import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import VerticalSpace from "../../../VerticalSpace";
import RadioButton from "../../../Common/RadioButton";
import Label from "../../../Common/Label";
import HorizontalSpace from "../../../HorizontalSpace";
import CustomCheckbox from "../../../Common/CustomCheckbox";
import RowContainer from "../../../Common/RowContainer";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import ColumnContainer from "../../../Common/ColumnContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  baseURL,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../api/apiClient";
import Toast from "../../../Common/Toast";
import NestedMenu from "../../../ParticlesDropdown/NestedMenu";
import "../../../../stylesheets/theme.css";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";
import {
  ensureParticleValue1AtEnd,
  getNewInputValue,
  getParticleReadOnlyStatus,
  particlePairValues,
} from "../../../../utils/particlePairUtils";
import { Pair } from "../../../../types";

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface ConjugationList {
  id?: number;
  value1: string;
  value2: string;
  value3: string;
  isReadOnly1?: boolean;
  isReadOnly2?: boolean;
  isReadOnly3?: boolean;
}

const ConjugationList = () => {
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedColumn1, setIsCheckedColumn1] = useState(true);
  const [isCheckedColumn2, setIsCheckedColumn2] = useState(true);
  const [isCheckedColumn3, setIsCheckedColumn3] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Light");
  const [column1, setColumn1] = useState<DropdownValue | null>(null);
  const [column2, setColumn2] = useState<DropdownValue | null>(null);
  const [column3, setColumn3] = useState<DropdownValue | null>(null);
  const [conjugationList, setConjugationList] = useState<ConjugationList[]>([]);

  const [conjugationListId, setConjugationListId] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [conjugationListDelete, setConjugationListDelete] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [errors, setErrors] = useState<{
  //   [key: number]: { value1?: string; value2?: string; value3?: string };
  // }>({});
  const [errors, setErrors] = useState<{
    column1?: string;
    column2?: string;
    column3?: string;
    conjugationList: {
      [key: number]: { value1?: string; value2?: string; value3?: string };
    };
  }>({
    conjugationList: {},
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  useEffect(() => {
    fetchConjugationListDetails();
  }, [flashcardContentId]);

  const fetchConjugationListDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-conjugationlist/${flashcardContentId}`
      );

      const conjugationListData = response?.data?.data;

      if (conjugationListData) {
        setConjugationListId(conjugationListData.id || "");
        setSelectedOption(conjugationListData.lightTheme || "Light");
        setIsCheckedColumn1(conjugationListData.isC1Visible);
        setIsCheckedColumn2(conjugationListData.isC2Visible);
        setIsCheckedColumn3(conjugationListData.isC3Visible);
        const { column1, column2, column3 } = conjugationListData;

        // Fetch data for each column and set them
        const column1Data = await fetchParticleById(column1);
        setColumn1(column1Data);

        const column2Data = await fetchParticleById(column2);
        setColumn2(column2Data);

        const column3Data = await fetchParticleById(column3);
        setColumn3(column3Data);

        // isC1Visible;
        const fetchedConjugationList: ConjugationList[] =
          conjugationListData.conjugationList || [];

        const updatedConjugationList = updateConjugationListWithReadOnlyFlags(
          fetchedConjugationList,
          column1Data,
          column2Data,
          column3Data
        );

        setConjugationList(updatedConjugationList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching conjugation list details:", error);
    }
  };

  const updateConjugationListWithReadOnlyFlags = (
    list: ConjugationList[],
    column1Data: any,
    column2Data: any,
    column3Data: any
  ): ConjugationList[] => {
    try {
      return list.map((item) => {
        const isReadOnly1 = column1Data
          ? getParticleReadOnlyStatus(column1Data)
          : true;
        const isReadOnly2 = column2Data
          ? getParticleReadOnlyStatus(column2Data)
          : true;
        const isReadOnly3 = column3Data
          ? getParticleReadOnlyStatus(column3Data)
          : true;

        return {
          ...item,
          isReadOnly1,
          isReadOnly2,
          isReadOnly3,
        };
      });
    } catch (error) {
      console.error("Error in updateConjugationListWithReadOnlyFlags:", error);
      return list; // Return original list if there's an error
    }
  };

  const handleCheckboxChangeColumn1 = () => {
    setIsCheckedColumn1(!isCheckedColumn1);
  };

  const handleCheckboxChangeColumn2 = () => {
    setIsCheckedColumn2(!isCheckedColumn2);
  };

  const handleCheckboxChangeColumn3 = () => {
    setIsCheckedColumn3(!isCheckedColumn3);
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const addConjugationList = () => {
    // Generate input values based on selected dropdowns
    const newConjugation = {
      value1: getNewInputValue(column1) || "",
      isReadOnly1: column1 ? getParticleReadOnlyStatus(column1) : true,
      value2: getNewInputValue(column2) || "",
      isReadOnly2: column2 ? getParticleReadOnlyStatus(column2) : true,
      value3: getNewInputValue(column3) || "",
      isReadOnly3: column3 ? getParticleReadOnlyStatus(column3) : true,
    };

    setConjugationList((prevList) => [...prevList, newConjugation]);
  };

  const removeVerb = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const response = await deleteRequest(
          `${baseURL}/lesson/delete-conjugationlist-value/${id}`
        );
        Toast(response?.data.statusMessage, "success");
        setConjugationList(conjugationList.filter((verb) => verb.id !== id));
      } catch (error) {
        console.error("Error deleting conjugation:", error);
      }
    } else {
      const updatedList = [...conjugationList];
      updatedList.splice(index, 1);
      setConjugationList(updatedList);
    }
  };

  const handleParticlesDropdownChange = (
    item: DropdownValue,
    columnIndex: number
  ) => {
    const newInputValue = getNewInputValue(item);
    const isReadOnly = getParticleReadOnlyStatus(item);

    setConjugationList((prevList) => {
      if (prevList.length === 0) {
        return [
          {
            value1: columnIndex === 0 ? newInputValue : "",
            isReadOnly1: columnIndex === 0 ? isReadOnly : true,
            value2: columnIndex === 1 ? newInputValue : "",
            isReadOnly2: columnIndex === 1 ? isReadOnly : true,
            value3: columnIndex === 2 ? newInputValue : "",
            isReadOnly3: columnIndex === 2 ? isReadOnly : true,
          },
        ];
      }

      return prevList.map((verb) => ({
        ...verb,
        ...(columnIndex === 0 && {
          value1: newInputValue,
          isReadOnly1: isReadOnly,
        }),
        ...(columnIndex === 1 && {
          value2: newInputValue,
          isReadOnly2: isReadOnly,
        }),
        ...(columnIndex === 2 && {
          value3: newInputValue,
          isReadOnly3: isReadOnly,
        }),
      }));
    });

    // Remove errors for all objects in conjugationList
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (newErrors.conjugationList) {
        Object.keys(newErrors.conjugationList).forEach((key) => {
          const index = Number(key); // Convert key to number explicitly
          if (newErrors.conjugationList[index]) {
            newErrors.conjugationList[index] = {
              ...newErrors.conjugationList[index],
              ...(columnIndex === 0 && { value1: "" }),
              ...(columnIndex === 1 && { value2: "" }),
              ...(columnIndex === 2 && { value3: "" }),
            };
          }
        });
      }

      return newErrors;
    });
  };

  const handleDropdownChange1 = (item: DropdownValue) => {
    setColumn1(item);
    setErrors((prevErrors) => ({ ...prevErrors, column1: "" }));
    handleParticlesDropdownChange(item, 0);
  };

  const handleDropdownChange2 = (item: DropdownValue) => {
    setColumn2(item);
    setErrors((prevErrors) => ({ ...prevErrors, column2: "" }));
    handleParticlesDropdownChange(item, 1);
  };

  const handleDropdownChange3 = (item: DropdownValue) => {
    setColumn3(item);
    setErrors((prevErrors) => ({ ...prevErrors, column3: "" }));
    handleParticlesDropdownChange(item, 2);
  };

  const fetchParticleById = async (id: number) => {
    if (id) {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    }
  };

  const handleSave = async () => {
    // Validate columns
    let hasError = false;

    if (!column1 && isCheckedColumn1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column1: "Column 1 is required",
      }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column1: "",
      }));
    }

    if (!column2 && isCheckedColumn2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column2: "Column 2 is required",
      }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column2: "",
      }));
    }

    if (!column3 && isCheckedColumn3) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column3: "Column 3 is required",
      }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        column3: "",
      }));
    }

    if (!isCheckedColumn1 && !isCheckedColumn2 && !isCheckedColumn3) {
      Toast("At least one column must be selected.", "error");
      return;
    }

    if (conjugationList.length === 0) {
      Toast("Please add at least one verb.", "error");
      return;
    }

    // Validate conjugation list fields
    const newErrors: {
      [key: number]: { value1?: string; value2?: string; value3?: string };
    } = {};

    conjugationList.forEach((item, index) => {
      newErrors[index] = {};
      if (!item.value1 && isCheckedColumn1) {
        newErrors[index].value1 = "Value 1 is required";
        hasError = true;
      }
      if (!item.value2 && isCheckedColumn2) {
        newErrors[index].value2 = "Value 2 is required";
        hasError = true;
      }
      if (!item.value3 && isCheckedColumn3) {
        newErrors[index].value3 = "Value 3 is required";
        hasError = true;
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      conjugationList: newErrors,
    }));

    if (hasError) {
      // Handle error state, display error messages
      return;
    }

    try {
      const data = {
        flashCardContentComponentId: flashcardContentId,
        column1: column1 ? column1.id : null,
        column2: column2 ? column2.id : null,
        column3: column3 ? column3.id : null,
        conjugations: conjugationList,
        lightTheme: selectedOption,
        isC1Visible: isCheckedColumn1,
        isC2Visible: isCheckedColumn2,
        isC3Visible: isCheckedColumn3,
      };
      let response;
      setIsLoading(true);
      if (conjugationListId) {
        response = await postRequest(
          `${baseURL}/lesson/update-conjugationList/${conjugationListId}`,
          data
        );

        Toast(response?.data.statusMessage, "success");
      } else {
        response = await postRequest(
          `${baseURL}/lesson/create-conjugationList`,
          data
        );
      }
      if (response?.data?.success) {
        const responseData = response.data.data.verbData;
        const oneListData = response.data.data.oneListData;
        setConjugationList(responseData);
        setConjugationListId(oneListData.id);
        setIsCheckedColumn1(oneListData.isC1Visible);
        setIsCheckedColumn2(oneListData.isC2Visible);
        setIsCheckedColumn3(oneListData.isC3Visible);
        setSelectedOption(oneListData.lightTheme);

        const [column1Data, column2Data, column3Data] = await Promise.all([
          fetchParticleById(oneListData.column1),
          fetchParticleById(oneListData.column2),
          fetchParticleById(oneListData.column3),
        ]);
        setColumn1(column1Data);
        setColumn2(column2Data);
        setColumn3(column3Data);
        navigate(-1);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteConjugationList = async (
    index: number,
    id: number
  ) => {
    if (id !== undefined) {
      try {
        const response = await deleteRequest(
          `${baseURL}/lesson/delete-conjugationlist-value/${id}`
        );

        setConjugationList(conjugationList.filter((verb) => verb.id !== id));
        setShowDeleteDialog(false);
        setConjugationListDelete(null);
        Toast(response?.data.statusMessage, "success");
      } catch (error) {
        console.error("Error deleting conjugation-list:", error);
        setShowDeleteDialog(false);
      }
    } else {
      const updatedList = [...conjugationList];
      updatedList.splice(index, 1);
      setConjugationList(updatedList);
    }
  };

  const removeVerbList = (index: number) => {
    const updatedList = [...conjugationList];
    updatedList.splice(index, 1);
    setConjugationList(updatedList);
  };

  const handleDeleteConjugation = (
    index: number,

    id?: number
  ) => {
    if (id !== undefined) {
      setConjugationListDelete(id);
      setDeleteDialogMessage(`Are you sure you want to delete this verb?`);
      setOnConfirmDelete(
        () => () => handleConfirmDeleteConjugationList(index, id)
      );
      setShowDeleteDialog(true);
    } else {
      removeVerbList(index);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setConjugationListDelete(null);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  const sortedConjugationList = conjugationList.sort((a, b) =>
    a.id && b.id ? a.id - b.id : 0
  );

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            List
          </h4>
          <VerticalSpace height={30} />
          <div
            className="container"
            style={{ maxWidth: "1240px", padding: "5px" }}
          >
            <div
              className="p-4"
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
              }}
            >
              {" "}
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <div className="d-flex" style={{ gap: "8px" }}>
                    <RadioButton
                      checked={selectedOption === "Light"}
                      onChange={() => handleOptionChange("Light")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      light
                    </Label>
                    <HorizontalSpace width={20} />
                    <RadioButton
                      checked={selectedOption === "Dark"}
                      onChange={() => handleOptionChange("Dark")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      dark
                    </Label>
                  </div>
                </div>
              </div>
              <VerticalSpace height={10} />
              <RowContainer gap="30px" className="conjugation-list-content">
                <ColumnContainer flex="1" className="conjugation-list-column">
                  <RowContainer>
                    <CustomCheckbox
                      checked={isCheckedColumn1}
                      onChange={handleCheckboxChangeColumn1}
                    />
                    <Label fontSize="20px" fontWeight={700}>
                      Column 1
                    </Label>
                  </RowContainer>
                  <VerticalSpace height={13} />
                  <NestedMenu
                    data={column1 ? [column1!] : []}
                    onSelectItem={(item) =>
                      handleDropdownChange1(item as DropdownValue)
                    }
                    borderColor={
                      errors.column1 && isCheckedColumn1 ? "#dc3545" : "#D9D9D9"
                    }
                    disabled={!isCheckedColumn1}
                  />
                  <VerticalSpace height={30} />
                  <Label
                    fontSize="20px"
                    fontWeight={700}
                    padding="0px 0px 0px 10px"
                  >
                    Verb
                  </Label>
                </ColumnContainer>
                <ColumnContainer flex="1" className="conjugation-list-column">
                  <RowContainer>
                    <CustomCheckbox
                      checked={isCheckedColumn2}
                      onChange={handleCheckboxChangeColumn2}
                    />
                    <Label fontSize="20px" fontWeight={700}>
                      Column 2
                    </Label>
                  </RowContainer>
                  <VerticalSpace height={13} />
                  <NestedMenu
                    data={column2 ? [column2!] : []}
                    onSelectItem={(item) =>
                      handleDropdownChange2(item as DropdownValue)
                    }
                    borderColor={
                      errors.column2 && isCheckedColumn2 ? "#dc3545" : "#D9D9D9"
                    }
                    disabled={!isCheckedColumn2}
                  />
                  <VerticalSpace height={30} />
                  <Label
                    fontSize="20px"
                    fontWeight={700}
                    padding="0px 0px 0px 10px"
                  >
                    Verb
                  </Label>
                </ColumnContainer>
                <ColumnContainer flex="1" className="conjugation-list-column">
                  <RowContainer>
                    <CustomCheckbox
                      checked={isCheckedColumn3}
                      onChange={handleCheckboxChangeColumn3}
                    />
                    <Label fontSize="20px" fontWeight={700}>
                      Column 3
                    </Label>
                  </RowContainer>
                  <VerticalSpace height={13} />
                  <NestedMenu
                    data={column3 ? [column3!] : []}
                    onSelectItem={(item) =>
                      handleDropdownChange3(item as DropdownValue)
                    }
                    borderColor={
                      errors.column3 && isCheckedColumn3 ? "#dc3545" : "#D9D9D9"
                    }
                    disabled={!isCheckedColumn3}
                  />
                  <VerticalSpace height={30} />
                  <Label
                    fontSize="20px"
                    fontWeight={700}
                    padding="0px 0px 0px 10px"
                  >
                    Verb
                  </Label>
                </ColumnContainer>
                <ColumnContainer flex="0"></ColumnContainer>
                <ColumnContainer gap="2px"></ColumnContainer>
              </RowContainer>
              <VerticalSpace
                height={16}
                className="verticalspace-conjugation"
              />
              <ColumnContainer className="conjugation-list-inputbox-content">
                <div
                  className="container col inputbox-content d-flex flex-column"
                  style={{ gap: "12px", padding: "0" }}
                >
                  {sortedConjugationList.map((verb, index) => (
                    <RowContainer
                      key={index}
                      gap="30px"
                      alignItem="center"
                      className="conjugation-list-inputbox"
                    >
                      <ColumnContainer
                        flex="1"
                        className="conjugation-list-column"
                      >
                        <input
                          type="text"
                          value={verb.value1}
                          onChange={(e) => {
                            const updatedList = [...conjugationList];
                            // updatedList[index].value1 = e.target.value;
                            updatedList[index].value1 =
                              ensureParticleValue1AtEnd(
                                e.target.value,
                                column1?.particleValue1
                              );
                            setConjugationList(updatedList);

                            if (errors.conjugationList[index]?.value1) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                conjugationList: {
                                  ...prevErrors.conjugationList,
                                  [index]: {
                                    ...prevErrors.conjugationList[index],
                                    value1: "",
                                  },
                                },
                              }));
                            }
                          }}
                          className={`form-control ${
                            errors.conjugationList[index]?.value1
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder=""
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                          disabled={!isCheckedColumn1}
                          readOnly={verb.isReadOnly1}
                        />
                      </ColumnContainer>
                      <ColumnContainer
                        flex="1"
                        className="conjugation-list-column"
                      >
                        <input
                          type="text"
                          value={verb.value2}
                          onChange={(e) => {
                            const updatedList = [...conjugationList];
                            // updatedList[index].value2 = e.target.value;
                            updatedList[index].value2 =
                              ensureParticleValue1AtEnd(
                                e.target.value,
                                column2?.particleValue1
                              );
                            setConjugationList(updatedList);

                            if (errors.conjugationList[index]?.value2) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                conjugationList: {
                                  ...prevErrors.conjugationList,
                                  [index]: {
                                    ...prevErrors.conjugationList[index],
                                    value2: "",
                                  },
                                },
                              }));
                            }
                          }}
                          className={`form-control ${
                            errors.conjugationList[index]?.value2
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder=""
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                          disabled={!isCheckedColumn2}
                          readOnly={verb.isReadOnly2}
                        />
                      </ColumnContainer>
                      <ColumnContainer
                        flex="1"
                        className="conjugation-list-column"
                      >
                        <input
                          type="text"
                          value={verb.value3}
                          onChange={(e) => {
                            const updatedList = [...conjugationList];
                            // updatedList[index].value3 = e.target.value;
                            updatedList[index].value3 =
                              ensureParticleValue1AtEnd(
                                e.target.value,
                                column3?.particleValue1
                              );
                            setConjugationList(updatedList);

                            if (errors.conjugationList[index]?.value3) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                conjugationList: {
                                  ...prevErrors.conjugationList,
                                  [index]: {
                                    ...prevErrors.conjugationList[index],
                                    value3: "",
                                  },
                                },
                              }));
                            }
                          }}
                          className={`form-control ${
                            errors.conjugationList[index]?.value3
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder=""
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                          disabled={!isCheckedColumn3}
                          readOnly={verb.isReadOnly3}
                        />
                      </ColumnContainer>
                      <div
                        style={{ cursor: "pointer", width: "fit-content" }}
                        onClick={() => handleDeleteConjugation(index, verb.id)}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{
                            WebkitUserSelect: "none",
                            userSelect: "none",
                          }}
                        />
                      </div>
                    </RowContainer>
                  ))}
                </div>
              </ColumnContainer>
              <VerticalSpace height={56} />
              <button
                type="button"
                style={{
                  color: "#100F15",
                  background: "none",
                  border: "1px solid #100F15",
                  borderRadius: "10px",
                  padding: "14px 19px",
                  fontSize: "14px",
                  width: "auto",
                }}
                onClick={addConjugationList}
              >
                Add new group
              </button>
            </div>
          </div>
          <VerticalSpace height={230} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              maxWidth: "100%",
              height: "12%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            {" "}
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default ConjugationList;
