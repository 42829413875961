import { DropdownValue, Pair } from "../types";

export const particlePairValues = (
  item: DropdownValue,
  index: number,
  updatedPairs: Pair[]
) => {
  const particleValue = item.particleValue?.trim() || "";
  const particleValue1 = item.particleValue1?.trim() || "";

  const newInputValue = (
    particleValue1 ? `${particleValue}${particleValue1}`.trim() : particleValue
  )
    .replace("...", "")
    .trim();

  updatedPairs[index].dropdownValue = item;
  updatedPairs[index].inputValue = newInputValue;

  // Set isReadOnly based on conditions
  if (particleValue === "" && particleValue1 === "") {
    updatedPairs[index].isReadOnly = false; // Editable
  } else if (particleValue !== "..." && particleValue1 !== "") {
    updatedPairs[index].isReadOnly = true; // Read-only
  } else if (particleValue1 === "") {
    updatedPairs[index].isReadOnly = true;
  } else {
    updatedPairs[index].isReadOnly = false; // Only `particleValue` editable
  }
};

export const getParticleReadOnlyStatus = (particleData: {
  particleValue: string;
  particleValue1: string;
}): boolean => {
  const particleValue = particleData.particleValue?.trim() || "";
  const particleValue1 = particleData.particleValue1?.trim() || "";

  if (particleValue === "" && particleValue1 === "") {
    return false;
  } else if (particleValue !== "..." && particleValue1 !== "") {
    return true;
  } else if (particleValue1 === "") {
    return true;
  }

  return false;
};

export const ensureParticleValue1AtEnd = (
  inputValue: string,
  particleValue1?: string
): string => {
  const fixedValue = particleValue1?.trim() || "";

  if (!fixedValue) {
    return inputValue;
  }

  // Ensure particleValue1 is always present at the end
  if (!inputValue.endsWith(fixedValue)) {
    return inputValue.replace(fixedValue, "").trim() + fixedValue;
  }

  return inputValue;
};

export const getNewInputValue = (item: DropdownValue | null) => {
  if (!item) return "";

  const particleValue = item.particleValue?.trim() || "";
  const particleValue1 = item.particleValue1?.trim() || "";

  return (
    particleValue1 ? `${particleValue}${particleValue1}`.trim() : particleValue
  )
    .replace("...", "")
    .trim();
};
