// //api route request
import axios, { AxiosResponse, AxiosError } from "axios";
import { getToken } from "../services/authService";
import Toast from "../Components/Common/Toast";

export const baseURL: string = process.env.REACT_APP_BASE_URL || "";

interface ErrorResponse {
  statusMessage?: string;
  error?: string;
  statusCode: number;
}

async function makeRequest(
  method: string,
  url: string,
  data?: any,
  token = ""
) {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });

    return response;
  } catch (error) {
    // if (error instanceof AxiosError) {
    //   const errorMessage =
    //     error.response?.data?.statusMessage || "Something Went Wrong..!";
    //   Toast(errorMessage, "error");
    // } else {
    //   Toast("An unexpected error occurred.", "error");
    // }
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const apifield = axiosError.response?.data?.error;
      if (axiosError.response && axiosError.response?.data?.statusMessage) {
        // The request was made and the server responded with a status code
        const errorMessage = axiosError.response.data.statusMessage;
        const errorCode = axiosError.response.data.statusCode;
        if (errorCode === 401) {
          // Redirect to login page on 401 error
          window.location.href = "https://admin.lexik.app/login";
          localStorage.clear();
        }
        if (!errorMessage.includes("not found")) {
          Toast(errorMessage, "error");
        }
      }
    } else {
      Toast("An unexpected error occurred.", "error");
    }
  }
}

export async function getRequest(
  url: string
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  return makeRequest("get", url, undefined, auth?.token);
}

export async function postRequest(
  url: string,
  data?: any
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  return makeRequest("post", url, data, auth?.token);
}

export async function putRequest(
  url: string,
  data: any
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  return makeRequest("put", url, data, auth?.token);
}

export async function patchRequest(
  url: string,
  data: any
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  return makeRequest("patch", url, data, auth?.token);
}

export async function deleteRequest(
  url: string,
  data?: any
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  return makeRequest("delete", url, data, auth?.token);
}

export async function multiPostRequest(
  url: string,
  data: FormData
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorMessage =
        error.response?.data?.statusMessage || "Something Went Wrong..!";

      Toast(errorMessage, "error");
    } else {
      Toast("An unexpected error occurred.", "error");
    }
  }
}

export async function multiPartPUTRequest(
  url: string,
  data: FormData
): Promise<AxiosResponse | undefined> {
  const auth = getToken();
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorMessage =
        error.response?.data?.statusMessage || "Something Went Wrong..!";
      Toast(errorMessage, "error");
    } else {
      Toast("An unexpected error occurred.", "error");
    }
  }
}
