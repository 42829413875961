// authService.ts
import { baseURL, getRequest, postRequest } from "../api/apiClient";

export const handleAction = async (action: string, data: any) => {
  switch (action) {
    case "login":
      return login(data.email, data.password);
    default:
      throw new Error(`Invalid action: ${action}`);
  }
};

export const login = async (email: string, password: string) => {
  try {
    const reqData = {
      email,
      password,
      isAdmin: true  
    }
    const response = await postRequest(`${baseURL}/login`,reqData);
    const { token, user } = response?.data.data; // Assuming the API returns a token
    saveToken(token, user?.fullName, user?.email, user?.uId, user?.status); // Save the token
    
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const saveToken = (
  token: string,
  fullName: string,
  email: string,
  uId: string,
  status: string
) => {
  const expiresIn = 24 * 60 * 60;
  const expirationTime = Date.now() + expiresIn * 1000;
  const userDataWithExpiration = { token,fullName, email, uId, expirationTime, status };
  localStorage.setItem("userData", JSON.stringify(userDataWithExpiration));
};

export const getToken = () => {
  const userDataString = localStorage.getItem("userData");
  if (!userDataString) {
    return null;
  }
  const userData = JSON.parse(userDataString);
  if (!userData.expirationTime || Date.now() > userData.expirationTime) {
    localStorage.clear();
    return null;
  }
  return userData;
};

export const clearSessionStorage = () => {
  localStorage.removeItem("userData");
};

export const removeToken = () => {
  localStorage.removeItem("token"); // Remove token from localStorage
};

export default {
   handleAction, 
   login,
   saveToken,
   getToken,
   removeToken,
}