import { useEffect, useState } from "react";
import RowContainer from "../../Components/Common/RowContainer";
import "../../stylesheets/indexpage.css";
import { baseURL, getRequest } from "../../api/apiClient";
import user from "../../assets/Images/logo/user.png";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../stylesheets/common.css";
import UsersCounts from "../../Components/Dashboard/UsersCount";
import LessonCard from "../../Components/Dashboard/LessonsProgress";
import LessonCount from "../../Components/Dashboard/LessonsCount";
import TicketsCount from "../../Components/Dashboard/Tickets";
import PackagesCount from "../../Components/Dashboard/Packages";
import CountriesCounts from "../../Components/Dashboard/CountriesCount";
import styled from "styled-components";
import { dotformatDate } from "../../utils/formatDate";
import ShimmerEffect from "../../Components/ShimmerEffect";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    flex-direction: column;
    padding: 10px;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 670px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Dashboard = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<any>({});

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    // setIsLoading(true);

    const endpoints = [
      { key: "subscriptions", url: "/dashboard/subscriptions/counts" },
      { key: "stripe", url: "/dashboard/stripe/counts" },
      { key: "lessons", url: "/dashboard/lessons/counts" },
      { key: "gender", url: "/dashboard/gender/counts" },
    ];
    
    // Call each API independently
    endpoints.forEach(async ({ key, url }) => {
        const response = await getRequest(`${baseURL}${url}`);
        if (response?.data?.success) {
          setDashboardData((prevData: any) => ({
            ...prevData,
            [key]: response.data.data,
          }));
        }
    });
  };

  const userCountsData = [
    {
      name: "Premium Users",
      users: dashboardData?.subscriptions?.premiumUsers || 0,
      progress: dashboardData?.subscriptions?.lastMonthPremiums || 0,
      isLoss: false,
    },
    {
      name: "Freemium Users",
      users:
        dashboardData?.subscriptions?.freemiumUsers?.totalFreemiumUsers || 0,
      progress:
        dashboardData?.subscriptions?.freemiumUsers
          ?.newFreemiumUsersLastMonth || 0,
      isLoss: false,
    },
    {
      name: "Free to paid Users",
      users:
        dashboardData?.subscriptions?.freeToPaidUsers?.totalFreeToPaidUsers ||
        0,
      progress:
        dashboardData?.subscriptions?.freeToPaidUsers
          ?.newFreeToPaidUsersLastMonth || 0,
      isLoss: false,
    },
  ];

  const appCountsData = [
    {
      name: "In-app purchase",
      users: `€${
        dashboardData?.stripe?.appPurchaseUsers?.totalPurchaseUsers || 0
      }`,
      progress:
        dashboardData?.stripe?.appPurchaseUsers?.newPurchaseUsersLastMonth || 0,
      isLoss: true,
    },
    {
      name: "Revenue subscriptions",
      users: `€${dashboardData?.stripe?.getDataFromStripes?.totalRevenue || 0}`,
      progress:
        dashboardData?.stripe?.getDataFromStripes?.lastMonthRevenue || 0,
      isLoss: false,
    },
    {
      name: "Membership Renewal",
      users: dashboardData?.stripe?.getDataFromStripes?.totalRenewalCount || 0,
      progress:
        dashboardData?.stripe?.getDataFromStripes?.lastMonthRenewalCount || 0,
      isLoss: false,
    },
  ];

  const lessonsData =
    dashboardData?.lessons?.mostPopularLesson &&
    dashboardData?.lessons?.mostPopularLesson?.map(
      (mostPopularLesson: any) => ({
        level: mostPopularLesson.level,
        name: mostPopularLesson.themeName,
        users: mostPopularLesson.totalUsers,
        progress: mostPopularLesson.completionPercentage,
      })
    );

  const lessonsCount = [
    {
      totalLessons: dashboardData?.lessons?.totalLessonCount?.totalCount || 0,
      lastMonthLessons:
        dashboardData?.lessons?.totalLessonCount?.lastMonthCount || 0,
      courses: [
        {
          level: "A1",
          users: 25000,
          progress: 90,
        },
        {
          level: "A2",
          users: 50000,
          progress: 75,
        },
        {
          level: "B1",
          users: 70000,
          progress: 50,
        },
      ],
    },
  ];

  const packages = [
    {
      level: "A1",
      name: "declinación",
      totalCount: 1500,
    },
    {
      level: "A2",
      name: "declinación...",
      totalCount: 1250,
    },
    {
      level: "A2",
      name: "präteritum",
      totalCount: 999,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 600,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 23,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 23,
    },
  ];

  const countriesData =
    dashboardData?.gender?.userCountByCountry &&
    dashboardData?.gender?.userCountByCountry?.map(
      (userCountByCountry: any) => ({
        name: userCountByCountry.name,
        users: userCountByCountry.users,
        progress: userCountByCountry.progress,
      })
    );

  const totalGenderCount = {
    women: 0,
    male: 0,
    other: 0,
  };

  dashboardData?.gender?.gender?.forEach((item: any) => {
    if (item?.gender === "women") {
      totalGenderCount.women = parseInt(item.count, 10);
    } else if (item?.gender === "male") {
      totalGenderCount.male = parseInt(item.count, 10);
    } else {
      totalGenderCount.other += parseInt(item.count, 10);
    }
  });

  // useEffect(() => {
  //   let timeLine = gsap.timeline();

  //   timeLine.from(".row-container", {
  //     opacity: 0,
  //     y: -40,
  //     duration: 0.3,
  //     ease: "power2.out",
  //     stagger: 0.3,
  //   });

  //   timeLine.from(".user-data", {
  //     opacity: 0,
  //     y: 40,
  //     duration: 0.3,
  //     ease: "power2.out",
  //   });

  //   timeLine.from(".left-container", {
  //     opacity: 0,
  //     x: -40,
  //     duration: 0.3,
  //     ease: "power2.out",
  //     stagger: 0.2,
  //   });

  //   timeLine.from(".bottom-container", {
  //     opacity: 0,
  //     y: 40,
  //     duration: 0.3,
  //     ease: "power2.out",
  //   });

  //   timeLine.from(".right-container", {
  //     opacity: 0,
  //     x: 40,
  //     duration: 0.3,
  //     ease: "power2.out",
  //     stagger: 0.2,
  //   });
  // }, [isLoading]);

  return (
    <>
      <div className="container-fluid">
        <div
          className="p-3 d-flex main-page"
          style={{ gap: "30px", flexDirection: "column" }}
        >
          <ScrollableRowContainer gap="33px">
            {!dashboardData.subscriptions ? (
              <ShimmerEffect height={135} />
            ) : (
              <RowContainer
                bgColor="#37176C"
                borderRadius="24px"
                padding="20px"
                display="unset"
                className="user"
              >
                <UsersCounts counts={userCountsData} />
              </RowContainer>
            )}
            {!dashboardData.stripe ? (
              <ShimmerEffect height={135} />
            ) : (
              <RowContainer
                bgColor="#323235"
                borderRadius="24px"
                padding="20px"
                display="unset"
                className="user-box"
              >
                <UsersCounts counts={appCountsData} />
              </RowContainer>
            )}
          </ScrollableRowContainer>

          <ScrollableRowContainer gap="33px">
            <RowContainer flexDirection="column" gap="24px">
              {!dashboardData.lessons ? (
                <ShimmerEffect height={300} />
              ) : (
                <LessonCard lessons={lessonsData} />
              )}
              {!dashboardData.lessons ? (
                <ShimmerEffect height={175} />
              ) : (
                <LessonCount lessonsCount={lessonsCount} />
              )}
            </RowContainer>
            <NewRowContainer gap="24px">
              {!dashboardData.gender ? (
                <ShimmerEffect height={500} />
              ) : (
                <CountriesCounts
                  countrie={countriesData}
                  gender={totalGenderCount}
                />
              )}
              <NewRowContainer flexDirection="column" flex="3" gap="24px">
                {!dashboardData.gender ? (
                  <ShimmerEffect width={230} height={300} />
                ) : (
                  <PackagesCount packageCount={packages} />
                )}
                {!dashboardData.gender ? (
                  <ShimmerEffect width={230} height={180} />
                ) : (
                  <TicketsCount
                    open={dashboardData?.gender?.tickets.Open || 0}
                    solved={dashboardData?.gender?.tickets.Solved || 0}
                    userImage={user || ""}
                    name={dashboardData?.gender?.lastLogin?.name || ""}
                    updatedAt={
                      dotformatDate(
                        dashboardData?.gender?.lastLogin?.lastLoginTime
                      ) || ""
                    }
                  />
                )}
              </NewRowContainer>
            </NewRowContainer>
          </ScrollableRowContainer>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
