import ColumnContainer from "../Common/ColumnContainer";
import Container from "../Common/Container";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import "../../stylesheets/common.css";
import VerticalSpace from "../VerticalSpace";
import formatUsersCount from "../formatUsersCount";
import styled from "styled-components";

interface packagesProgress {
  level: string;
  name: string;
  totalCount: string | number;
}

interface PackageCountProps {
  packageCount: packagesProgress[];
}

const getLevelBackgroundColor = (level: string) => {
  switch (level) {
    case "A1":
      return "#00A3FF";
    case "A2":
      return "#FF9E00";
    case "B1":
      return "#592ACB";
    default:
      return "#121217";
  }
};

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 670px) {
    width: auto;
  }
`;

const PackagesCount: React.FC<PackageCountProps> = ({ packageCount }) => {
  return (
    <Container
      className="user-box right-container"
      borderRadius="24px"
      padding="15px"
      bgColor="#FFFFFF"
      width="100%"
    >
      <RowContainer alignItem="center">
        <Label fontSize="20px" fontWeight={700} color="#323235">
          Packages
        </Label>
      </RowContainer>
      <VerticalSpace height={10} />
      <RowContainer
        alignItem="center"
        flexDirection="column"
        className="scrollable-packages"
        // style={{
        //   maxHeight: "240px",
        //   overflowY: packageCount.length > 5 ? "auto" : "auto",
        // }}
      >
        {packageCount?.map((packages, index) => (
          <RowContainer
            key={index}
            alignItem="center"
            borderBottom={
              index !== packageCount?.length - 1
                ? "1px solid rgba(16, 15, 21, 0.1)"
                : "none"
            }
            padding="7px"
          >
            <NewRowContainer flex="2">
              <Label
                fontSize="12px"
                fontWeight={700}
                color="#FFFFFF"
                borderRadius="12px"
                bgColor={getLevelBackgroundColor(packages?.level)}
                width="16px"
                height="20px"
                padding="5px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Label>
              <NewLabel
                fontSize="13px"
                fontWeight={400}
                color="#121217"
                width="100px"
              >
                {packages?.name}
              </NewLabel>
            </NewRowContainer>
            <Label
              fontSize="15px"
              fontWeight={700}
              color="#121217"
              width="max-content"
            >
              {formatUsersCount(Number(packages?.totalCount))}
            </Label>
          </RowContainer>
        ))}
      </RowContainer>
    </Container>
  );
};
export default PackagesCount;
