import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Typography, Box, Button } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import BottomArrow from "../../assets/bottomArrowIcon.svg";
import RightArrow from "../../assets/RightSideArrow.svg";
// import "../Dashboard/NestedMenu.css";
import "../ParticlesDropdown/NestedMenu.css";
import ParticlesType from "../Common/ParticleType";
import RowContainer from "../Common/RowContainer";
import { baseURL, getRequest } from "../../api/apiClient";
import ColumnContainer from "../Common/ColumnContainer";
import CombinedParticles from "../Common/CombinedParticles";
import TextIcon from "../../assets/Text.svg";
import { useDispatch, useSelector } from "react-redux";
import { getParticles, selectParticlesData } from "../../redux/particlesReducerSlice";
import { AppDispatch } from "../../redux/store";

interface Item {
  id: number;
  name: string;
  pId: number;
  children?: Item[];
  particleType?: string;
  particleValue?: string;
  particleType1?: string;
  particleValue1?: string;
}

interface MenuPosition {
  top: number;
  left: number;
}

interface NestedMenuProps {
  data: Item[] | null;
  onSelectItem: (item: Item) => void;
  disabled?: boolean;
  borderColor?: string;
}

const NestedMenu: React.FC<NestedMenuProps> = ({
  data,
  onSelectItem,
  disabled = false,
  borderColor = "#D9D9D9",
}) => {
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const [selectedItem, setSelectedItem] = useState<Item | null>(
    data?.[0] || null
  );
  const dispatch: AppDispatch = useDispatch();
  const [menuData, setMenuData] = useState<Item[]>([]);

  const menuDatas = useSelector(selectParticlesData) as unknown as Item[];

  useEffect(()=>{
    dispatch(getParticles());
  },[dispatch]);

  useEffect(() => {
    if(menuDatas){
    setMenuData(menuDatas);
    }
  }, [menuDatas]);

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedItem(data[0]);
    }
  }, [data]);

  // useEffect(() => {
  //   fetchDropdownData();
  // }, []);

  // const fetchDropdownData = async () => {
  //   try {
  //     const response = await getRequest(`${baseURL}/particle/get-dropdown`);
  //     if (!response) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const data = response.data.data;
  //     setMenuData(data);
  //   } catch (error) {
  //     console.error("Error fetching dropdown data:", error);
  //   }
  // };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setMenuPosition({
        top: event.currentTarget.getBoundingClientRect().bottom,
        left: event.currentTarget.getBoundingClientRect().left,
      });
    }
  };

  const handleItemClick = (item: Item) => {
    if (!disabled) {
      setSelectedItem(item);
      setMenuPosition(null);
      onSelectItem(item);
    }
  };

  const renderParticle = (type: string) => {
    switch (type) {
      case "Masculine":
      case "Femenine":
      case "Neutral":
      case "Plural":
      case "Sujeto":
      case "Nominative":
      case "Accusative":
      case "Dative":
      case "Genetive":
      case "Nom. Complement":
      case "Conjugated verb":
      case "Infinitive":
      case "Participle":
      case "zu":
      case "Negation":
        return (
          <ParticlesType
            width="32px"
            height="24px"
            padding="3.5px 10px"
            bgColor={
              type === "Masculine"
                ? "#0063F9"
                : type === "Femenine"
                ? "#FD4B92"
                : type === "Neutral"
                ? "#6D787E"
                : type === "Plural"
                ? "#E0E0E6"
                : type === "Sujeto"
                ? "#009BFF"
                : type === "Nominative"
                ? "#009BFF"
                : type === "Accusative"
                ? "#28A138"
                : type === "Dative"
                ? "#EB002C"
                : type === "Genetive"
                ? "#FF7700"
                : type === "Nom. Complement"
                ? "#009BFF"
                : type === "Conjugated verb"
                ? "#7542E4"
                : type === "Infinitive"
                ? "#37176C"
                : type === "Participle"
                ? "#37176C"
                : type === "zu"
                ? "#37176C"
                : type === "Negation"
                ? "#591212"
                : ""
            }
            borderRadius={
              type === "Sujeto"
                ? "20px 4px 4px 20px"
                : type === "Nominative"
                ? "20px 4px 4px 20px"
                : type === "Nom. Complement"
                ? "4px 20px 20px 4px"
                : type === "Conjugated verb"
                ? "20px"
                : type === "Infinitive"
                ? "4px 20px 20px 4px"
                : type === "Participle"
                ? "20px"
                : type === "zu"
                ? "20px 4px 4px 20px"
                : type === "Negation"
                ? "20px"
                : "4px"
            }
          />
        );
      case "Conjugated verb p1":
        return (
          <RowContainer gap="0px">
            <ParticlesType
              width="26px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#7542E4"
              borderRadius="10px 0px 0px 10px"
              className="user"
            />
            <ColumnContainer>
              <ParticlesType width="6px" height="7px" bgColor="#7542E4" />
              <ParticlesType width="6px" height="7px" bgColor="#7542E4" />
            </ColumnContainer>
          </RowContainer>
        );
      case "prefix":
        return (
          <RowContainer gap="0px">
            <ColumnContainer backgroundColor="#37176C">
              <ParticlesType
                width="6px"
                height="7px"
                bgColor="rgb(248, 248, 248)"
              ></ParticlesType>
              <ParticlesType
                width="6px"
                height="7px"
                bgColor="rgb(248, 248, 248)"
              ></ParticlesType>
            </ColumnContainer>
            <ParticlesType
              width="26px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#37176C"
              borderRadius="0px 10px 10px 0px"
            ></ParticlesType>
          </RowContainer>
        );
      case "imperative":
        return (
          <RowContainer gap="0px">
            <ParticlesType
              width="20px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#7542E4"
            ></ParticlesType>
            <ParticlesType
              width="12px"
              height="24px"
              borderBottom="12px solid transparent"
              borderLeft="12px solid #7542E4"
              borderTop="12px solid transparent"
            ></ParticlesType>
          </RowContainer>
        );
      case "Te":
        return (
          <CombinedParticles
            // padding="10px"
            width="32px"
            alignItems="center"
            justifyContent="center"
            height="24px"
            innerColors={["#FFFFFF"]}
            bgColor="#00B69C"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Ka":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            alignItems="center"
            gap="2px"
            justifyContent="center"
            innerColors={["#12525E", "#FFFFFF"]}
            bgColor="#00B69C"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Mo":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            gap="2px"
            alignItems="center"
            display="flex"
            justifyContent="center"
            innerColors={["#12525E", "#12525E", "#FFFFFF"]}
            bgColor="#00B69C"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Lo":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            gap="1px"
            alignItems="center"
            justifyContent="center"
            innerColors={["#12525E", "#12525E", "#12525E", "#FFFFFF"]}
            bgColor="#00B69C"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Alt. accusative":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            alignItems="center"
            gap="2px"
            justifyContent="center"
            innerColors={["#52525F", "#28A138"]}
            bgColor="#EBEBEC"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Alt. dative":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            alignItems="center"
            gap="2px"
            justifyContent="center"
            innerColors={["#EB002C", "#52525F"]}
            bgColor="#EBEBEC"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Alt. dative and accusative":
        return(
         <CombinedParticles
          width="32px"
          height="24px"
          alignItems="center"
          gap="2px"
          justifyContent="center"
          innerColors={["#EB002C", "#28A138"]}
          bgColor="#EBEBEC"
          borderRadius="4px"
         ></CombinedParticles>
        );
      case "acusative":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            alignItems="center"
            gap="2px"
            justifyContent="center"
            innerColors={["#28A138"]}
            bgColor="#EBEBEC"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "dative":
        return (
          <CombinedParticles
            width="32px"
            height="24px"
            alignItems="center"
            gap="2px"
            justifyContent="center"
            innerColors={["#EB002C"]}
            bgColor="#EBEBEC"
            borderRadius="4px"
          ></CombinedParticles>
        );
      case "Conjunctions":
        return (
          <RowContainer gap="0px" alignItem="center">
            <ColumnContainer gap="6px">
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
              {/* <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType> */}
            </ColumnContainer>
            <ParticlesType
              width="28px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#FF9E00"
              borderRadius="4px"
            ></ParticlesType>
          </RowContainer>
        );
      case "Subconjunction":
        return (
          <RowContainer gap="0px" alignItem="center">
            <ColumnContainer gap="6px">
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
            </ColumnContainer>
            <ParticlesType
              width="28px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#FF9E00"
              borderRadius="4px"
            ></ParticlesType>
          </RowContainer>
        );
      case "Conjunctive adverbs":
        return (
          <RowContainer gap="0px" alignItem="center">
            <ColumnContainer gap="2px">
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
              <ParticlesType
                width="4px"
                height="4px"
                bgColor="#FF9E00"
              ></ParticlesType>
            </ColumnContainer>
            <ParticlesType
              width="28px"
              height="24px"
              padding="3.5px 10px"
              bgColor="#FF9E00"
              borderRadius="4px"
            ></ParticlesType>
          </RowContainer>
        );
      case "Empty":
        return (
          <ParticlesType
            width="32px"
            height="24px"
            padding="3.5px 10px"
            borderRadius="4px"
            border="1px solid #E0E0E6"
          ></ParticlesType>
        );
      case "Text":
        return (
          <ParticlesType
            width="32px"
            height="24px"
            padding="2px"
            borderRadius="4px"
            border="1px solid #E0E0E6"
            alignItems="center"
            justifyContent="center"
          >
            <img src={TextIcon} alt="text-icon" style={{ opacity: "24%" }} />
          </ParticlesType>
        );
      default:
        return null;
    }
  };

  const renderParticle1 = (type: string, value: string) => {
    const isValueEmpty = !value || value.trim() === "";
    switch (type) {
      case "Subject":
      case "Neutral":
      case "Femenine":
      case "Masculine":
      case "Plural":
      case "Sujeto":
      case "Accusative":
      case "Dative":
      case "Genitive":
        return (
          <ParticlesType
            padding={
              isValueEmpty &&
              [
                "Masculine",
                "Femenine",
                "Neutral",
                "Plural",
                "Sujeto",
                "Dative",
                "Accusative",
                "Genitive",
              ].includes(type)
                ? "3.5px 5px"
                : type === "Sujeto" ||
                  type === "Accusative" ||
                  type === "Dative" ||
                  type === "Genitive"
                ? "3px 0px 3px 4px"
                : "3px 4px 3px 0px"
            }
            bgColor={
              type === "Masculine"
                ? "#0063F9"
                : type === "Femenine"
                ? "#FD4B92"
                : type === "Neutral"
                ? "#6D787E"
                : type === "Sujeto"
                ? "#009BFF"
                : type === "Accusative"
                ? "#28A138"
                : type === "Dative"
                ? "#EB002C"
                : type === "Genitive"
                ? "#FF7700"
                : type === "Plural"
                ? "#E0E0E6"
                : "#ffffff"
            }
            borderRadius="4px"
            color={type === "Plural" ? "#121217" : "#ffffff"}
            fontSize="14px"
            fontWeight={400}
            height={
              isValueEmpty &&
              [
                "Masculine",
                "Femenine",
                "Neutral",
                "Plural",
                "Sujeto",
                "Dative",
                "Accusative",
                "Genitive",
              ].includes(type)
                ? "27px"
                : undefined
            }
          >
            {isValueEmpty ? "" : value}
          </ParticlesType>
        );
      default:
        return null;
    }
  };
  
  const renderMenuItems = (items: Item[]) => {
    return (
      <div className="menu-items-container">
        {items.map((item) => {
          if (item.children && item.children.length > 0) {
            return (
              <NestedMenuItem
                key={item.id}
                label={item.name}
                parentMenuOpen={!!menuPosition}
                rightIcon={<img src={RightArrow} alt="right-arrow" />}
                onPointerEnter={() => {}}
                onPointerLeave={() => {}}
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                placeholder=""
                className="nestedMenuItem"
              >
                {renderMenuItems(item.children)}
              </NestedMenuItem>
            );
          } else {
            return (
              <MenuItem
                key={item.id}
                onClick={() => handleItemClick(item)}
                className="menuItem"
              >
                <RowContainer gap="0px" width="auto">
                  {(item.particleType || item.particleValue) &&
                  (item.particleType1 || item.particleValue1) ? (
                    <>
                      {item.particleType &&
                        (item.particleValue || "\u00A0") &&
                        renderParticle1(
                          item.particleType,
                          item.particleValue || "\u00A0"
                        )}
                      {item.particleType1 &&
                        (item.particleValue1 || "\u00A0") &&
                        renderParticle1(
                          item.particleType1,
                          item.particleValue1 || "\u00A0"
                        )}
                    </>
                  ) : (
                    item.particleType &&
                    !item.particleType1 &&
                    !item.particleValue &&
                    !item.particleValue1 &&
                    renderParticle(item.particleType)
                  )}
                </RowContainer>
                {item.name}
              </MenuItem>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div
      className="row d-flex user"
      style={{
        backgroundColor: disabled ? "#e9ecef" : "#FFFFFF",
        border: `1px solid ${borderColor}`,
        padding: "7px 0px",
        margin: "0",
        borderRadius: "10px",
        width: "100%",
        pointerEvents: disabled ? "none" : "auto",
        cursor:'pointer'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        borderRadius="10px"
        onClick={handleMenuClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {selectedItem ? (
            <RowContainer>
              <RowContainer gap="0px" width="min-content">
                {(selectedItem.particleType || selectedItem.particleValue) &&
                (selectedItem.particleType1 || selectedItem.particleValue1) ? (
                  <>
                    {selectedItem.particleType &&
                      (selectedItem.particleValue || "\u00A0") &&
                      renderParticle1(
                        selectedItem.particleType,
                        selectedItem.particleValue || "\u00A0"
                      )}
                    {selectedItem.particleType1 &&
                      (selectedItem.particleValue1 || "\u00A0") &&
                      renderParticle1(
                        selectedItem.particleType1,
                        selectedItem.particleValue1 || "\u00A0"
                      )}
                  </>
                ) : (
                  selectedItem.particleType &&
                  !selectedItem.particleType1 &&
                  !selectedItem.particleValue &&
                  !selectedItem.particleValue1 &&
                  renderParticle(selectedItem.particleType)
                )}
              </RowContainer>
              <RowContainer>{selectedItem.name}</RowContainer>
            </RowContainer>
          ) : (
            "Select Item"
          )}
        </Typography>
        {selectedItem ? (
          <img src={RightArrow} alt="bottom-arrow" />
        ) : (
          <img src={BottomArrow} alt="bottom-arrow" />
        )}
      </Box>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition || undefined}
        className="custom-menu-paper"
      >
        {menuData && renderMenuItems(menuData)}
      </Menu>
    </div>
  );
};

export default NestedMenu;
