import React from "react";
import ContentLoader from "react-content-loader";

interface ShimmerProps {
  width?: number;
  height?: number;
  borderRadius?: number;
}

const ShimmerEffect: React.FC<ShimmerProps> = ({
  width,
  height = 160,
  borderRadius = 24,
}) => (
  <ContentLoader
    speed={2}
    width={width || "100%"}
    height={height}
    viewBox={`0 0 ${width || "100%"} ${height}`}
    backgroundColor="#e0e0e0"
    foregroundColor="#ecebeb"
  >
    <rect
      x="0"
      y="0"
      rx={borderRadius}
      ry={borderRadius}
      width={width || "100%"}
      height={height}
    />
  </ContentLoader>
);

export default ShimmerEffect;
