import Container from "../Common/Container";
import "../../stylesheets/common.css";
import RowContainer from "../Common/RowContainer";
import Label from "../Common/Label";
import ColumnContainer from "../Common/ColumnContainer";
import "../../stylesheets/LessonCardProgress.css";
import formatUsersCount from "../formatUsersCount";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import VerticalSpace from "../VerticalSpace";
import styled from "styled-components";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CountrieProgress {
  name: string;
  users: string | number;
  progress: number;
}

interface GenderData {
  women: number;
  male: number;
  other: number;
}

interface CountriesCountsProps {
  countrie: CountrieProgress[];
  gender: GenderData;
}


const NewRowContainer = styled(RowContainer)`
  @media (max-width: 510px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;

const CountriesCounts: React.FC<CountriesCountsProps> = ({
  countrie,
  gender,
}) => {
  const progressColors = [
    "#FF9E00",
    "#7542E4",
    "#00A3FF",
    "#EB002C",
    "#28A138",
  ];

  const genderData = {
    datasets: [
      {
        data: [gender.women, gender.male, gender.other],
        backgroundColor: ["#7542E4", "#00A3FF", "#FF9E00"],
        hoverBackgroundColor: ["#7542E4", "#00A3FF", "#FF9E00"],
      },
    ],
  };

  return (
    <Container
      className="user-box bottom-container"
      borderRadius="24px"
      padding="15px"
      bgColor="#FFFFFF"
      width="100%"
    >
      <RowContainer>
        <Label fontSize="20px" fontWeight={700} color="#121217">
          Countries
        </Label>
      </RowContainer>
      <VerticalSpace height={10} />
      <ColumnContainer padding="10px" className="scrollable-container">
        {countrie?.length === 0 ? (
          <RowContainer
            alignItem="center"
            height="261px"
            justifyContent="center"
          >
            <Label fontSize="22px" color="#7542E4" textAlign="center">
              No countries available
            </Label>
          </RowContainer>
        ) : (
          countrie?.map((countrie, index) => (
            <RowContainer
              className="dashboaed-scale"
              key={index}
              flexDirection="column"
              gap="0px"
            >
              <Label fontSize="13px" fontWeight={400} color="#100F15">
                {countrie?.name}
              </Label>
              <RowContainer flex="7" alignItem="center">
                <div
                  style={{
                    width: "100%",
                    height: "10px",
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: `${countrie?.progress}%`,
                      height: "100%",
                      backgroundColor:
                        progressColors[index % progressColors.length],
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
                <RowContainer flex="3" justifyContent="flex-end">
                  <Label fontSize="13px" fontWeight={700} color="#121217">
                    {formatUsersCount(Number(countrie?.users))}
                  </Label>
                </RowContainer>
              </RowContainer>
            </RowContainer>
          ))
        )}
      </ColumnContainer>
      <VerticalSpace height={10} />
      <RowContainer>
        <Label fontSize="20px" fontWeight={700} color="#121217">
          Gender
        </Label>
      </RowContainer>
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        padding="10px"
      >
        <NewRowContainer justifyContent="space-between" alignItem="center">
          <ColumnContainer>
            <Pie
              className="dashboard-pie"
              data={genderData}
              style={{ maxWidth: "150px" }}
            />
          </ColumnContainer>
          <ColumnContainer flexDirection="row">
            <RowContainer
              alignItem="center"
              flexDirection="column"
              justifyContent="center"
              gap="5px"
            >
              <RowContainer alignItem="center" gap="5px">
                <RowContainer
                  bgColor="#7542E4"
                  width="12px"
                  height="12px"
                  borderRadius="50%"
                ></RowContainer>
                <Label fontSize="13px" fontWeight={400} color="#100F15">
                  Female
                </Label>
              </RowContainer>
              <Label
                className="dashboaed-score"
                fontSize="15px"
                fontWeight={700}
                color="#323235"
              >
                {formatUsersCount(gender?.women)}
              </Label>
            </RowContainer>
            <RowContainer
              alignItem="center"
              flexDirection="column"
              justifyContent="center"
              gap="5px"
            >
              <RowContainer alignItem="center" gap="5px">
                <RowContainer
                  bgColor="#00A3FF"
                  width="12px"
                  height="12px"
                  borderRadius="50%"
                ></RowContainer>
                <Label fontSize="13px" fontWeight={400} color="#100F15">
                  Male
                </Label>
              </RowContainer>
              <Label
                className="dashboaed-score"
                fontSize="15px"
                fontWeight={700}
                color="#323235"
              >
                {formatUsersCount(gender?.male)}
              </Label>
            </RowContainer>
            <RowContainer
              alignItem="center"
              flexDirection="column"
              justifyContent="center"
              gap="5px"
            >
              <RowContainer alignItem="center" gap="5px">
                <RowContainer
                  bgColor="#FF9E00"
                  width="12px"
                  height="12px"
                  borderRadius="50%"
                ></RowContainer>
                <Label fontSize="13px" fontWeight={400} color="#100F15">
                  Other
                </Label>
              </RowContainer>
              <Label
                className="dashboaed-score"
                fontSize="15px"
                fontWeight={700}
                color="#323235"
              >
                {formatUsersCount(gender?.other)}
              </Label>
            </RowContainer>
          </ColumnContainer>
        </NewRowContainer>
      </RowContainer>
    </Container>
  );
};

export default CountriesCounts;
