import React, { useState, useRef, useEffect } from "react";
import VerticalSpace from "../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../assets/deleteIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  baseURL,
  deleteRequest,
  getRequest,
  multiPostRequest,
} from "../../api/apiClient";
import Toast from "../Common/Toast";
import "../../stylesheets/theme.css";
import Loader from "../../utils/Loading";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import ColumnContainer from "../Common/ColumnContainer";
import styled from "styled-components";
import gsap from "gsap";
import "../../stylesheets/common.css";
import DeleteLoader from "../../utils/deleteLoader";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import RadioButton from "../Common/RadioButton";

interface LessonImage {
  lessonName: string;
  image: File | null;
  isWhiteBackground?: boolean;
}

interface CreateConfigProps {
  homeScreenVideo: File | null;
  homeScreenVideoThumbnail: File | null;
  homeScreenMembershipImage: File | null;
  homeScreenChallengeImage: File | null;
  homeScreenNotificationImage: File | null;
  homeScreenLessonImage: File | null;
  homeScreenOneLessonImage: File | null;
  homeScreenMoreLessonImage: File | null;
  homeScreenOneNotificationImage: File | null;
  homeScreenOneChallengeImage: File | null;
  homeScreenMoreNotificationImage: File | null;
  homeScreenMoreChallengeImage: File | null;
  favouriteFlashcard: File[] | null;
  lessonImages: LessonImage[];
}

const NewLabel = styled(Label)`
  @media (max-width: 769px) {
    font-size: 19px;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

const NewRowContainers = styled(RowContainer)`
  @media (max-width: 769px) {
    flex-direction: row;
  }
`;

const NewColumnContainers = styled(ColumnContainer)`
  @media (max-width: 769px) {
    min-width: auto;
  }
`;

const NewColumnContainer = styled(ColumnContainer)`
  @media (max-width: 769px) {
    max-width: -webkit-fill-available;
  }
`;

const NewLabels = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 769px) {
    max-width: 100px;
  }
`;

const CreateConfig = () => {
  const { id } = useParams();
  const [config, setConfig] = useState<CreateConfigProps>({
    homeScreenVideo: null,
    homeScreenVideoThumbnail: null,
    homeScreenMembershipImage: null,
    homeScreenChallengeImage: null,
    homeScreenNotificationImage: null,
    homeScreenLessonImage: null,
    homeScreenOneLessonImage: null,
    homeScreenMoreLessonImage: null,
    homeScreenOneNotificationImage: null,
    homeScreenOneChallengeImage: null,
    homeScreenMoreNotificationImage: null,
    homeScreenMoreChallengeImage: null,
    favouriteFlashcard: [],
    lessonImages: [],
  });

  const fileInputRefs = useRef<
    Record<
      keyof Omit<CreateConfigProps, "lessonImages">,
      HTMLInputElement | null
    > & {
      lessonImages: (HTMLInputElement | null)[];
    }
  >({
    homeScreenVideo: null,
    homeScreenVideoThumbnail: null,
    homeScreenMembershipImage: null,
    homeScreenChallengeImage: null,
    homeScreenNotificationImage: null,
    homeScreenLessonImage: null,
    homeScreenOneLessonImage: null,
    homeScreenMoreLessonImage: null,
    homeScreenOneNotificationImage: null,
    homeScreenOneChallengeImage: null,
    homeScreenMoreNotificationImage: null,
    homeScreenMoreChallengeImage: null,
    favouriteFlashcard: null,
    lessonImages: [],
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [deleteImageParams, setDeleteImageParams] = useState<{
    key: keyof CreateConfigProps;
    index?: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    setIsLoading(true);
    const response = await getRequest(`${baseURL}/config/get-all-config/${id}`);
    if (response?.data) {
      setConfig(response?.data.data.data);
    }
    setIsLoading(false);
  };

  const handleChooseFileClick = (
    key: keyof CreateConfigProps,
    index?: number
  ) => {
    if (key === "lessonImages" && index !== undefined) {
      const inputElement = fileInputRefs.current.lessonImages[index];
      if (inputElement) {
        inputElement.click();
      }
    } else {
      const inputElement = fileInputRefs.current[key];
      if (inputElement instanceof HTMLInputElement) {
        inputElement.click();
      }
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof CreateConfigProps,
    index?: number
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setConfig((prevConfig) => {
        if (key === "favouriteFlashcard") {
          const currentFiles = prevConfig.favouriteFlashcard || [];

          if (files.length > 10) {
            Toast("You can select a maximum of 10 images.", "error");
            return prevConfig;
          }

          const updatedFiles = [...currentFiles, ...files];

          return { ...prevConfig, favouriteFlashcard: updatedFiles };
        } else if (key === "lessonImages" && index !== undefined) {
          const updatedLessonImages = [...prevConfig.lessonImages];

          // Ensure the entry exists before updating
          if (!updatedLessonImages[index]) {
            updatedLessonImages[index] = { lessonName: "", image: null };
          }

          updatedLessonImages[index] = {
            ...updatedLessonImages[index],
            image: files[0], // Only take the first file
          };
          return { ...prevConfig, lessonImages: updatedLessonImages };
        }
        return { ...prevConfig, [key]: files[0] };
      });
    }
  };

  const addNewLessonImage = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      lessonImages: [
        ...(prevConfig.lessonImages || []),
        { lessonName: "", image: null, isWhiteBackground: false },
      ],
    }));
  };

  const updateLessonImageFlag = (index: number, value: boolean) => {
    setConfig((prev) => {
      const updatedLessonImages = [...prev.lessonImages];
      updatedLessonImages[index] = {
        ...updatedLessonImages[index],
        isWhiteBackground: value,
      };
      return { ...prev, lessonImages: updatedLessonImages };
    });
  };

  const handleDeleteImage = (key: keyof CreateConfigProps, index?: number) => {
    setDeleteImageParams({ key, index });
    setDeleteDialogMessage(`Are you sure you want to delete this image?`);
    setShowDeleteDialog(true);
  };

  const onConfirmDelete = async () => {
    if (!deleteImageParams) return;

    const { key, index } = deleteImageParams;

    let imageType = key;
    let imageUrl = "";

    if (key === "lessonImages" && index !== undefined) {
      const lessonImages = config.lessonImages!;
      if (index < lessonImages.length) {
        const fileOrUrl = lessonImages[index].image;
        if (fileOrUrl instanceof File) {
          imageUrl = URL.createObjectURL(fileOrUrl);
        } else if (typeof fileOrUrl === "string") {
          imageUrl = fileOrUrl;
        }
      }
    } else if (key === "favouriteFlashcard" && index !== undefined) {
      const flashcards = config.favouriteFlashcard!;
      if (index < flashcards.length) {
        const fileOrUrl = flashcards[index];
        if (fileOrUrl instanceof File) {
          imageUrl = URL.createObjectURL(fileOrUrl);
        } else if (typeof fileOrUrl === "string") {
          imageUrl = fileOrUrl;
        }
      }
    } else {
      const fileOrUrl = config[key];
      if (typeof fileOrUrl === "string") {
        imageUrl = fileOrUrl;
      } else if (fileOrUrl instanceof File) {
        imageUrl = URL.createObjectURL(fileOrUrl);
      }
    }

    const reqData = {
      imageType: imageType,
      imageUrl: imageUrl,
      index: index,
    };

    if (!imageUrl.startsWith("blob:")) {
      setLoading(true);
      await deleteRequest(`${baseURL}/config/delete-config-image`, reqData);
      setLoading(false);
    }
    setConfig((prevConfig) => {
      if (key === "lessonImages" && index !== undefined) {
        const updatedLessonImages = [...prevConfig.lessonImages!];
        updatedLessonImages.splice(index, 1);
        return {
          ...prevConfig,
          lessonImages:
            updatedLessonImages.length > 0 ? updatedLessonImages : [],
        };
      } else if (key === "favouriteFlashcard" && index !== undefined) {
        const updateFlashcard = [...prevConfig.favouriteFlashcard!];
        updateFlashcard.splice(index, 1);
        return {
          ...prevConfig,
          favouriteFlashcard:
            updateFlashcard.length > 0 ? updateFlashcard : null,
        };
      } else {
        return {
          ...prevConfig,
          [key]: null,
        };
      }
    });

    // Close the delete confirmation dialog
    setShowDeleteDialog(false);
  };

  // Mapping of config keys to user-friendly field names
  const configFieldLabels: {
    [key in keyof Omit<CreateConfigProps, "lessonImages">]: string;
  } = {
    homeScreenVideo: "Banner video promo",
    homeScreenVideoThumbnail: "Banner video thumbnail promo",
    homeScreenMembershipImage: "Banner membership",
    homeScreenNotificationImage: "Banner notification promo",
    homeScreenOneNotificationImage: "Banner 1 notification active",
    homeScreenMoreNotificationImage: "Banner at least 2 notification active",
    homeScreenLessonImage: "Banner lesson promo",
    homeScreenOneLessonImage: "Banner 1 lesson active",
    homeScreenMoreLessonImage: "Banner at least 2 lesson active",
    homeScreenChallengeImage: "Banner challenge promo",
    homeScreenOneChallengeImage: "Banner 1 challenge active",
    homeScreenMoreChallengeImage: "Banner at least 2 challenges active",
    favouriteFlashcard: "Banner Favourite Flashcard Promo",
  };

  const removeEmptyLessons = () => {
    setConfig((prev) => {
      const filteredLessons = prev.lessonImages.filter(
        (lesson) => lesson.lessonName?.trim() !== "" || lesson.image
      );
      return { ...prev, lessonImages: filteredLessons };
    });
  };

  const handlePublishClick = async () => {
    removeEmptyLessons();
    const formData = new FormData();
    let hasMissingFiles = false;

    let lessonImageUrls: {
      lessonName: string;
      image: string;
      isWhiteBackground: boolean;
    }[] = [];

    for (const key of Object.keys(configFieldLabels)) {
      if (!(key in config)) {
        const fieldLabel =
          configFieldLabels[
            key as keyof Omit<CreateConfigProps, "lessonImages">
          ];
        Toast(`Field ${fieldLabel} is missing from the config`, "error");
        hasMissingFiles = true;
        break; // Stop after the first error
      }
    }

    if (Array.isArray(config.lessonImages) && config.lessonImages.length > 0) {
      for (const lesson of config.lessonImages) {
        if (!lesson.lessonName || !lesson.image) {
          Toast(`Each lesson must have a name and an image`, "error");
          hasMissingFiles = true;
          break;
        }
        if (lesson.image instanceof File) {
          const uploadFormData = new FormData();
          uploadFormData.append("file", lesson.image);

          try {
            setIsLoading(true);
            const uploadResponse = await multiPostRequest(
              `${baseURL}/file-upload`,
              uploadFormData
            );
            if (uploadResponse?.data?.data[0]?.uri) {
              lessonImageUrls.push({
                lessonName: lesson.lessonName,
                image: uploadResponse.data.data[0].uri,
                isWhiteBackground: lesson.isWhiteBackground ?? false,
              });
            }
          } catch (error) {
            setIsLoading(false);
            Toast(`Failed to upload ${lesson.lessonName} image`, "error");
            return;
          }
        } else if (typeof lesson.image === "string") {
          lessonImageUrls.push({
            lessonName: lesson.lessonName,
            image: lesson.image,
            isWhiteBackground: lesson.isWhiteBackground ?? false,
          });
        }
      }
    } else {
      Toast("At least one lesson image is required", "error");

      hasMissingFiles = true;
      setIsLoading(false);
    }

    // If any required field is missing, stop the execution
    if (hasMissingFiles) {
      return;
    }

    Object.entries(config).forEach(([key, value]) => {
      const fieldLabel =
        configFieldLabels[key as keyof Omit<CreateConfigProps, "lessonImages">];
      if (key === "lessonImages") {
        formData.append("lessonImages", JSON.stringify(lessonImageUrls));
      } else if (key === "favouriteFlashcard" && Array.isArray(value)) {
        if (value.length < 2) {
          Toast(`At least 2 images required for ${fieldLabel}`, "error");
          hasMissingFiles = true;
        }

        // Handle the favouriteFlashcard array
        value.forEach((item, index) => {
          if (item instanceof File) {
            formData.append(`favouriteFlashcardImages`, item as Blob);
          }
        });
      } else if (!value) {
        Toast(`Field ${fieldLabel} is required`, "error");
        hasMissingFiles = true;
      } else {
        formData.append(key, value as Blob);
      }
    });

    if (hasMissingFiles) {
      return;
    }

    setIsLoading(true);
    const response = await multiPostRequest(
      `${baseURL}/config/create-config`,
      formData
    );

    if (response?.data) {
      // setConfig(response?.data.data.config.data);
      const updatedConfig = response?.data.data.config.data;
      setConfig({
        ...updatedConfig,
        lessonImages: Array.isArray(updatedConfig.lessonImages)
          ? updatedConfig.lessonImages
          : [],
      });
      Toast(response?.data.statusMessage, "success");
    }

    setIsLoading(false);
  };

  const handleBackClick = () => {
    navigate("/configs");
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <VerticalSpace height={10} />
          <h4
            className="row-container"
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Home banner images
          </h4>
          <VerticalSpace height={30} />
          <div className="container" style={{ maxWidth: "1300px" }}>
            {/* Banner video promo */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewRowContainer gap="15px" flexWrap="wrap">
                {/* Banner home video promo */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner video promo
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenVideo && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenVideo = el)
                          }
                          hidden
                          accept="video/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenVideo")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            width: "auto",
                            fontSize: "14px",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenVideo")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenVideo && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenVideo === "string" ? (
                              <>
                                <video
                                  controls
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick("homeScreenVideo")
                                  }
                                >
                                  <source
                                    src={config?.homeScreenVideo}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenVideo = el)
                                  }
                                  hidden
                                  accept="video/*"
                                  onChange={(e) =>
                                    handleFileChange(e, "homeScreenVideo")
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <video
                                  controls
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                >
                                  <source
                                    src={URL.createObjectURL(
                                      config?.homeScreenVideo
                                    )}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenVideo.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenVideo");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner video thumbnail promo */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner video thumbnail promo
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenVideoThumbnail && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenVideoThumbnail =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenVideoThumbnail")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenVideoThumbnail")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenVideoThumbnail && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenVideoThumbnail ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenVideoThumbnail}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenVideoThumbnail"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenVideoThumbnail =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenVideoThumbnail"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenVideoThumbnail
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenVideoThumbnail.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenVideoThumbnail");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>

                {/* Banner membership */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner membership
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenMembershipImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenMembershipImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenMembershipImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenMembershipImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenMembershipImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenMembershipImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMembershipImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMembershipImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMembershipImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMembershipImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenMembershipImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenMembershipImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenMembershipImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
              </NewRowContainer>
            </div>
            <VerticalSpace height={20} />
            {/* Banner notifications */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewRowContainer gap="15px" flexWrap="wrap">
                {/* Banner notification promo */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner notification promo
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenNotificationImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenNotificationImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenNotificationImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenNotificationImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenNotificationImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenNotificationImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenNotificationImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenNotificationImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner 1 notification active */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner 1 notification active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenOneNotificationImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenOneNotificationImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              "homeScreenOneNotificationImage"
                            )
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick(
                              "homeScreenOneNotificationImage"
                            )
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenOneNotificationImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenOneNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenOneNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenOneNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenOneNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenOneNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenOneNotificationImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenOneNotificationImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage(
                                "homeScreenOneNotificationImage"
                              );
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner at least 2 notification active */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner at least 2 notification active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenMoreNotificationImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenMoreNotificationImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              "homeScreenMoreNotificationImage"
                            )
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick(
                              "homeScreenMoreNotificationImage"
                            )
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenMoreNotificationImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenMoreNotificationImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreNotificationImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreNotificationImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreNotificationImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreNotificationImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenMoreNotificationImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenMoreNotificationImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage(
                                "homeScreenMoreNotificationImage"
                              );
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
              </NewRowContainer>
            </div>
            <VerticalSpace height={20} />
            {/* Banner lesson active */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewRowContainer gap="15px" flexWrap="wrap">
                {/* Banner lesson promo*/}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner lesson promo
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenLessonImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        justifyContent="space-between"
                        gap="14px"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenLessonImage = el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenLessonImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenLessonImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenLessonImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenLessonImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenLessonImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenLessonImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenLessonImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(e, "homeScreenLessonImage")
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenLessonImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenLessonImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenLessonImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner 1 lesson active*/}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner 1 lesson active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenOneLessonImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        justifyContent="space-between"
                        gap="14px"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenOneLessonImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenOneLessonImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenOneLessonImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenOneLessonImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenOneLessonImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenOneLessonImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenOneLessonImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenOneLessonImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenOneLessonImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenOneLessonImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenOneLessonImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenOneLessonImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner at least 2 lesson active */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner at least 2 lesson active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenMoreLessonImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenMoreLessonImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenMoreLessonImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenMoreLessonImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenMoreLessonImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenMoreLessonImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreLessonImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreLessonImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreLessonImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreLessonImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenMoreLessonImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenMoreLessonImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenMoreLessonImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
              </NewRowContainer>
            </div>
            <VerticalSpace height={20} />
            {/* Banner challenges */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewRowContainer gap="15px" flexWrap="wrap">
                {/* Banner challenge promo */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner challenge promo
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenChallengeImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenChallengeImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenChallengeImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenChallengeImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenChallengeImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenChallengeImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenChallengeImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenChallengeImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner 1 challenge active */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner 1 challenge active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenOneChallengeImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenOneChallengeImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenOneChallengeImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick("homeScreenOneChallengeImage")
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenOneChallengeImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenOneChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenOneChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenOneChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenOneChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenOneChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenOneChallengeImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenOneChallengeImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenOneChallengeImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
                {/* Banner at least 2 challenges active */}
                <NewColumnContainer maxWidth="400px">
                  <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                    Banner at least 2 challenges active
                  </NewLabel>
                  <RowContainer>
                    {!config?.homeScreenMoreChallengeImage && (
                      <NewRowContainer
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <Label
                          fontSize="14px"
                          fontWeight={400}
                          color="#52525F"
                          width="max-content"
                        >
                          Upload an image jpg, png or svg
                        </Label>
                        <input
                          type="file"
                          ref={(el) =>
                            (fileInputRefs.current.homeScreenMoreChallengeImage =
                              el)
                          }
                          hidden
                          accept="image/*"
                          className="form-control"
                          onChange={(e) =>
                            handleFileChange(e, "homeScreenMoreChallengeImage")
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-center user"
                          style={{
                            color: "#100F15",
                            background: "none",
                            border: "1px solid #100F15",
                            borderRadius: "10px",
                            padding: "14px 30px",
                            fontSize: "14px",
                            width: "auto",
                          }}
                          onClick={() =>
                            handleChooseFileClick(
                              "homeScreenMoreChallengeImage"
                            )
                          }
                        >
                          Choose file
                        </button>
                      </NewRowContainer>
                    )}
                    {config?.homeScreenMoreChallengeImage && (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof config?.homeScreenMoreChallengeImage ===
                            "string" ? (
                              <>
                                <img
                                  src={config?.homeScreenMoreChallengeImage}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick(
                                      "homeScreenMoreChallengeImage"
                                    )
                                  }
                                />
                                <input
                                  type="file"
                                  ref={(el) =>
                                    (fileInputRefs.current.homeScreenMoreChallengeImage =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(
                                      e,
                                      "homeScreenMoreChallengeImage"
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(
                                    config?.homeScreenMoreChallengeImage
                                  )}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                  maxWidth="180px"
                                >
                                  {config?.homeScreenMoreChallengeImage.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("homeScreenMoreChallengeImage");
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    )}
                  </RowContainer>
                </NewColumnContainer>
              </NewRowContainer>
            </div>
            <VerticalSpace height={20} />
            {/* Banner Favourite Flashcard Promo */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewColumnContainers minWidth="350px">
                <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                  Banner Favourite Flashcard Promo
                </NewLabel>
                <RowContainer flexWrap="wrap" gap="15px">
                  <NewRowContainer
                    bgColor="#FFFFFF"
                    borderRadius="20px"
                    padding="14px 16px"
                    alignItem="center"
                    width="400px"
                    gap="14px"
                    justifyContent="space-between"
                  >
                    <Label
                      fontSize="14px"
                      fontWeight={400}
                      color="#52525F"
                      width="max-content"
                    >
                      Upload an image jpg, png or svg
                    </Label>
                    <input
                      type="file"
                      multiple
                      ref={(el) =>
                        (fileInputRefs.current.favouriteFlashcard = el)
                      }
                      hidden
                      accept="image/*"
                      className="form-control"
                      onChange={(e) =>
                        handleFileChange(e, "favouriteFlashcard")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-center user"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        borderRadius: "10px",
                        padding: "14px 30px",
                        width: "auto",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        handleChooseFileClick("favouriteFlashcard")
                      }
                    >
                      Choose file
                    </button>
                  </NewRowContainer>
                  {config?.favouriteFlashcard &&
                    config?.favouriteFlashcard.length > 0 &&
                    config?.favouriteFlashcard.map((image, index) => (
                      <>
                        <NewRowContainers
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="30px"
                          justifyContent="space-between"
                        >
                          <RowContainer alignItem="center">
                            {typeof image === "string" ? (
                              <>
                                <img
                                  src={image}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                  onClick={() =>
                                    handleChooseFileClick("favouriteFlashcard")
                                  }
                                />
                                <input
                                  type="file"
                                  multiple
                                  ref={(el) =>
                                    (fileInputRefs.current.favouriteFlashcard =
                                      el)
                                  }
                                  hidden
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileChange(e, "favouriteFlashcard")
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt="flash-image"
                                  style={{
                                    height: "55px",
                                    cursor: "pointer",
                                    objectFit: "contain",
                                  }}
                                />
                                <NewLabels
                                  color="#000000"
                                  fontSize="14px"
                                  fontWeight={400}
                                >
                                  {image.name}
                                </NewLabels>
                              </>
                            )}
                          </RowContainer>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteImage("favouriteFlashcard", index);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              alt="Delete"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </NewRowContainers>
                      </>
                    ))}
                </RowContainer>
              </NewColumnContainers>
            </div>
            <VerticalSpace height={20} />
            {/* Banner lesson images */}
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              <NewColumnContainers minWidth="350px">
                <NewLabel fontWeight={700} fontSize="20px" color="#000000">
                  Banner lesson images
                </NewLabel>
                {config.lessonImages?.map((lesson, index) => (
                  <RowContainer
                    key={index}
                    flexWrap="wrap"
                    gap="15px"
                    alignItem="center"
                  >
                    <div className="d-flex flex-column gap-2 custom-width">
                      <input
                        type="text"
                        value={lesson.lessonName || ""}
                        onChange={(e) => {
                          const updatedLessonImages = [...config.lessonImages];
                          updatedLessonImages[index].lessonName =
                            e.target.value;
                          setConfig((prev) => ({
                            ...prev,
                            lessonImages: updatedLessonImages,
                          }));
                        }}
                        placeholder=""
                        className="form-control"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                          maxWidth: "400px",
                        }}
                      />
                      <div
                        className="d-flex align-items-center lessons-top justify-content-between gap-2"
                        style={{ maxWidth: "400px" }}
                      >
                        <Label
                          fontSize="20px"
                          fontWeight={500}
                          marginLeft="6px"
                        >
                          Is image have white bg
                        </Label>{" "}
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <RadioButton
                            checked={lesson.isWhiteBackground === true}
                            onChange={() => updateLessonImageFlag(index, true)}
                          />
                          <Label fontSize="14px" fontWeight={400}>
                            True
                          </Label>
                          <RadioButton
                            checked={lesson.isWhiteBackground === false}
                            onChange={() => updateLessonImageFlag(index, false)}
                          />
                          <Label fontSize="14px" fontWeight={400}>
                            False
                          </Label>
                        </div>
                      </div>
                    </div>

                    {/* File Upload Section */}
                    {!lesson.image ? (
                      <>
                        <NewRowContainer
                          bgColor="#FFFFFF"
                          borderRadius="20px"
                          padding="14px 16px"
                          alignItem="center"
                          width="400px"
                          gap="14px"
                          justifyContent="space-between"
                        >
                          <Label
                            fontSize="14px"
                            fontWeight={400}
                            color="#52525F"
                            width="max-content"
                          >
                            Upload an image jpg, png or svg
                          </Label>
                          {/* Hidden File Input */}
                          <input
                            type="file"
                            ref={(el) => {
                              if (!fileInputRefs.current.lessonImages) {
                                fileInputRefs.current.lessonImages = [];
                              }
                              fileInputRefs.current.lessonImages[index] = el;
                            }}
                            hidden
                            accept="image/*"
                            className="form-control"
                            onChange={(e) =>
                              handleFileChange(e, "lessonImages", index)
                            }
                          />

                          <button
                            type="button"
                            className="btn btn-outline-secondary text-center user"
                            style={{
                              color: "#100F15",
                              background: "none",
                              border: "1px solid #100F15",
                              borderRadius: "10px",
                              padding: "14px 30px",
                              width: "auto",
                              fontSize: "14px",
                            }}
                            onClick={() =>
                              handleChooseFileClick("lessonImages", index)
                            }
                          >
                            Choose file
                          </button>
                        </NewRowContainer>
                      </>
                    ) : (
                      <NewRowContainers
                        bgColor="#FFFFFF"
                        borderRadius="20px"
                        padding="14px 16px"
                        alignItem="center"
                        width="400px"
                        gap="14px"
                        justifyContent="space-between"
                      >
                        <RowContainer alignItem="center">
                          {typeof lesson.image === "string" ? (
                            <>
                              <img
                                src={lesson.image}
                                alt={`lesson-image-${index}`}
                                style={{
                                  height: "55px",
                                  cursor: "pointer",
                                  objectFit: "contain",
                                  width: "inherit",
                                }}
                                onClick={() =>
                                  handleChooseFileClick("lessonImages", index)
                                }
                              />
                              {/* Hidden File Input */}
                              <input
                                type="file"
                                ref={(el) => {
                                  if (!fileInputRefs.current.lessonImages) {
                                    fileInputRefs.current.lessonImages = [];
                                  }
                                  fileInputRefs.current.lessonImages[index] =
                                    el;
                                }}
                                hidden
                                accept="image/*"
                                className="form-control"
                                onChange={(e) =>
                                  handleFileChange(e, "lessonImages", index)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={URL.createObjectURL(lesson.image)}
                                alt={`lesson-image-${index}`}
                                style={{
                                  height: "55px",
                                  cursor: "pointer",
                                  objectFit: "fill",
                                  width: "inherit",
                                }}
                              />
                              <NewLabels
                                color="#000000"
                                fontSize="14px"
                                fontWeight={400}
                                maxWidth="180px"
                              >
                                {lesson.image.name}
                              </NewLabels>
                            </>
                          )}
                        </RowContainer>

                        {/* Delete Button */}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDeleteImage("lessonImages", index)
                          }
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            alt="Delete"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </NewRowContainers>
                    )}
                  </RowContainer>
                ))}

                <VerticalSpace height={16} />

                {/* Add new lesson image button */}
                <button
                  type="button"
                  className="user"
                  style={{
                    color: "#100F15",
                    background: "none",
                    border: "1px solid #100F15",
                    borderRadius: "10px",
                    padding: "14px 20px",
                    fontSize: "14px",
                  }}
                  onClick={addNewLessonImage}
                >
                  Add new lesson image
                </button>
              </NewColumnContainers>
            </div>
            <VerticalSpace height={20} />
            <VerticalSpace height={120} />
          </div>
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 2,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handlePublishClick}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && <DeleteLoader />}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default CreateConfig;
