import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import HorizontalSpace from "../HorizontalSpace";
import "../../stylesheets/question.css";
import VerticalSpace from "../VerticalSpace";
import RadioButton from "../Common/RadioButton";
import deleteIcon from "../../assets/deleteIcon.svg";
import OptionCheckbox from "../Common/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import Label from "../Common/Label";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../Common/Toast";
import Container from "../Common/Container";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import "../../stylesheets/common.css";

interface Option {
  id: number;
  text: string;
  selected: boolean;
}

const CreateChallenge = () => {
  const { id } = useParams();
  const [questionId, setQuestionId] = useState("");
  const [question, setQuestion] = useState("");
  const [selectedOption, setSelectedOption] = useState("A1");
  const [options, setOptions] = useState<Option[]>([]);
  const [nextChallengeId, setNextChallengeId] = useState("");
  //  const [correctOptionIndex, setCorrectOptionIndex] = useState(0);
  const [correctOptionIndex, setCorrectOptionIndex] = useState<number | null>(
    null
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchQuestionData = async () => {
    if (id) {
      try {
        setLoading(true);
        const response = await getRequest(`${baseURL}/question/getById/${id}`); // Assuming this endpoint fetches data for a single question based on ID
        const {
          question,
          options,
          questionLevel,
          nextQuestionId,
          correctOptionIndex,
        } = response?.data.data;

        setQuestion(question);
        setSelectedOption(questionLevel);
        if (Array.isArray(options)) {
          setOptions(
            options.map((text, index) => ({
              id: index + 1,
              text,
              selected: correctOptionIndex.includes(index),
            }))
          );
        }
        setCorrectOptionIndex(correctOptionIndex);
        setNextChallengeId(nextQuestionId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching question data:", error);
      }
    }
  };

  useEffect(() => {
    fetchQuestionData();
  }, [id]);

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
    if (errors.question) {
      setErrors({ ...errors, question: "" });
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleInputChange = (id: number, value: string) => {
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, text: value };
        }
        return option;
      })
    );
    if (errors[`option${id - 1}`]) {
      setErrors({ ...errors, [`option${id - 1}`]: "" });
    }
  };

  const handleCheckboxChange = (id: number) => {
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, selected: true };
        } else {
          return { ...option, selected: false };
        }
      })
    );
    setCorrectOptionIndex(id - 1);
  };

  const addNewField = () => {
    if (options.length < 4) {
      const newOption = { id: options.length + 1, text: "", selected: false };
      setOptions([...options, newOption]);
    }
  };

  const removeField = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);

    const updatedOptionsWithIds = updatedOptions.map((option, i) => ({
      ...option,
      id: i + 1,
    }));
    setOptions(updatedOptionsWithIds);

    setCorrectOptionIndex(
      correctOptionIndex !== null && correctOptionIndex > 0
        ? correctOptionIndex - 0
        : 0
    );

    const errorKey = `option${index}`;
    const updatedErrors = { ...errors };
    delete updatedErrors[errorKey];
    setErrors(updatedErrors);
  };

  const validateInputs = () => {
    let tempErrors: { [key: string]: string } = {};
    if (!question) tempErrors.question = "Please enter a question.";
    options.forEach((option, index) => {
      if (!option.text)
        tempErrors[`option${index}`] = "Please enter an option.";
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleCreateChallenge = async () => {
    if (!question) {
      Toast("Please enter a question", "error");
      return;
    }
    if (options.length < 2) {
      Toast("Please provide at least two options", "error");
      return;
    }
    if (!validateInputs()) return;
    if (
      correctOptionIndex === null ||
      correctOptionIndex < 0 ||
      correctOptionIndex >= options.length
    ) {
      Toast("Please select a correct option", "error");
      return;
    }

    const data = {
      question,
      options: options.map((option) => option.text),
      questionLevel: selectedOption,
      correctOptionIndex: [correctOptionIndex],
    };

    try {
      setLoading(true);
      let response;
      const updateId = id || questionId;
      if (updateId) {
        // Edit mode: Update existing record
        response = await postRequest(
          `${baseURL}/question/update-question/${updateId}`,
          data
        );
        setLoading(false);
        Toast(response?.data.statusMessage, "success");
      } else {
        response = await postRequest(`${baseURL}/question/add-question`, data);

        if (response) {
          const { id, question, options, questionLevel, correctOptionIndex } =
            response?.data.data;
          setQuestionId(id);
          setQuestion(question);
          setSelectedOption(questionLevel);
          if (Array.isArray(options)) {
            setOptions(
              options.map((text, index) => ({
                id: index + 1,
                text,
                selected: correctOptionIndex.includes(index),
              }))
            );
          }
          setCorrectOptionIndex(correctOptionIndex);
        }
        Toast(response?.data.statusMessage, "success");
      }

      setLoading(false);
      //  navigate("/questions");
    } catch (error) {
      setLoading(false);
      console.error("Error handling question:", error);
    }
  };

  const handleBackClick = async () => {
    navigate(`/questions`);
  };

  const handleNextClick = async () => {
    if (nextChallengeId) navigate(`/questions/edit/${nextChallengeId}`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 class-main">
            <div className="d-flex justify-content-between">
              <div
                className="d-flex  align-items-center go-back"
                onClick={handleBackClick}
                style={{ width: "fit-content" }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="me-2"
                  style={{
                    backgroundColor: "#D9D9D9",
                    padding: "7px",
                    borderRadius: "8px",
                    color: "#000",
                    cursor: "pointer",
                  }}
                />
                <label
                  style={{
                    color: "#FF9E00",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Back
                </label>
              </div>
              {nextChallengeId && (
                <div
                  className="d-flex  align-items-center go-back"
                  onClick={handleNextClick}
                  style={{ width: "fit-content" }}
                >
                  <label
                    style={{
                      color: "#FF9E00",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Next
                  </label>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="ms-2"
                    style={{
                      backgroundColor: "#D9D9D9",
                      padding: "7px",
                      borderRadius: "8px",
                      color: "#000",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
            </div>
            <h4
              className="heading row-container"
              style={{
                textAlign: "center",

                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a question
            </h4>
            <VerticalSpace height={20} />

            <div
              className="container"
              style={{
                borderRadius: "30px",
                maxWidth: "960px",
                position: "relative",
              }}
            >
              <div
                className="col-12  p-4"
                style={{
                  borderRadius: "30px 30px 0px 0px",
                  backgroundColor: "#d9d9d9",
                  // backgroundColor: "red"
                }}
              >
                <Label
                  fontSize="20px"
                  padding="0px 0px 0px 8px"
                  fontWeight={700}
                >
                  Question
                </Label>
                <VerticalSpace height={10} />
                <div className="d-flex justify-content-between question align-items-center">
                  <input
                    type="text"
                    placeholder="Enter question"
                    className={`form-control ${
                      errors.question ? "is-invalid" : ""
                    }user`}
                    id="lessonstitle"
                    value={question}
                    onChange={handleQuestionChange}
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                    required
                  />
                  <HorizontalSpace width={10} />
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <RadioButton
                      checked={selectedOption === "A1"}
                      onChange={() => handleOptionChange("A1")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      A1
                    </Label>
                    <RadioButton
                      checked={selectedOption === "A2"}
                      onChange={() => handleOptionChange("A2")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      A2
                    </Label>

                    <RadioButton
                      checked={selectedOption === "B1"}
                      onChange={() => handleOptionChange("B1")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      B1
                    </Label>
                  </div>
                </div>
              </div>
              <div
                className="col-12  p-4"
                style={{
                  borderRadius: "0px 0px 30px 30px",
                  backgroundColor: "#d9d9d980",
                }}
              >
                <Label
                  fontSize="20px"
                  padding="0px 0px 0px 8px"
                  fontWeight={700}
                >
                  Answers
                </Label>
                <VerticalSpace height={10} />
                <div className="align-items-center">
                  <div className="" style={{ flexDirection: "column" }}>
                    {options.map((field, index) => (
                      <div
                        key={field.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Enter option"
                          className={`form-control ${
                            errors[`option${index}`] ? "is-invalid" : ""
                          } user`}
                          value={field.text}
                          onChange={(e) =>
                            handleInputChange(field.id, e.target.value)
                          }
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                        <HorizontalSpace width={1} />
                        <OptionCheckbox
                          checked={field.selected}
                          onChange={() => handleCheckboxChange(field.id)}
                        />
                        <HorizontalSpace width={2} />
                        <div style={{ cursor: "pointer" }}>
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            onClick={() => removeField(index)}
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    {options.length < 4 && (
                      <button
                        type="button"
                        className="user"
                        style={{
                          color: "#100F15",
                          background: "none",
                          border: "1px solid #100F15",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          padding: "10px 15px",
                          borderRadius: "10px",
                        }}
                        onClick={addNewField}
                      >
                        Add new field
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <VerticalSpace height={160} />
            <div
              className="container row d-flex justify-content-center publish-button"
              style={{
                position: "fixed",
                zIndex: 1,
                maxWidth: "100%",
                bottom: "0px",
                height: "12%",
                background:
                  "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  position: "fixed",
                  zIndex: 1,
                  bottom: "34px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-success d-flex justify-content-center user"
                  style={{
                    backgroundColor: "#FF9E00",
                    color: "#100F15",
                    height: "fit-content",
                    borderRadius: "10px",
                    padding: "10px 20%",
                    width: "400px",
                    fontSize: "14px",
                  }}
                  onClick={handleCreateChallenge}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateChallenge;
