import React, { useEffect, useRef, useState } from "react";

interface RoundedCornerParticleProps {
  bgColor: string;
  text: string;
  textColor?: string;
  textStyle?: React.CSSProperties;
  borderRadius?: number;
  verticalPadding?: number;
  horizontalPadding?: number;
  onTap?: () => void;
  hasOnlyBorder?: boolean;
  padding?: string; // Ensure this is a string type
}

export const RoundedCornerParticle: React.FC<RoundedCornerParticleProps> = ({
  bgColor,
  text,
  textColor,
  textStyle,
  borderRadius = 4.0,
  verticalPadding = 6,
  horizontalPadding = 10,
  onTap,
  hasOnlyBorder = false,
  padding,
}) => {
  const computedPadding =
    padding || `${verticalPadding}px ${horizontalPadding}px`;

  return (
    <div
      onClick={onTap}
      style={{
        backgroundColor: hasOnlyBorder ? "transparent" : bgColor,
        borderRadius: `${borderRadius}px`,
        border: hasOnlyBorder
          ? `1px solid rgba(0, 0, 0, 0.14)` // Replace with actual color
          : "none",
        padding: computedPadding, // Should work since computedPadding is string
        cursor: onTap ? "pointer" : "default",
        minWidth: "13px",
        minHeight: "36px",
      }}
    >
      <span style={textStyle || { color: textColor || "#FFFFFF" }}>
        {text.trim() === "" ? "  " : text}
      </span>
    </div>
  );
};

interface LeftRoundedCornerParticleProps {
  bgColor: string;
  text: string;
  textColor?: string;
  verticalPadding?: number;
  horizontalPadding?: number;
  padding?: string;
  radius?: string;
  onTap?: () => void;
}

export const LeftRoundedCornerParticle: React.FC<
  LeftRoundedCornerParticleProps
> = ({
  bgColor,
  text,
  textColor,
  verticalPadding = 6,
  horizontalPadding = 10,
  onTap,
  padding,
  radius,
}) => {
  const computedPadding =
    padding || `${verticalPadding}px ${horizontalPadding}px`;
  return (
    <div
      onClick={onTap}
      style={{
        backgroundColor: bgColor,
        borderRadius: radius || "4px",
        padding: computedPadding,
        cursor: onTap ? "pointer" : "default",
        width: "fit-content",
        minWidth: "13px",
        minHeight: "36px",
      }}
    >
      <span style={{ color: textColor || "#FFFFFF" }}>{text}</span>
    </div>
  );
};

interface RightRoundedCornerParticleProps {
  bgColor: string;
  text: string;
  textColor?: string;
  verticalPadding?: number;
  horizontalPadding?: number;
  onTap?: () => void;
}

export const RightRoundedCornerParticle: React.FC<
  RightRoundedCornerParticleProps
> = ({
  bgColor,
  text,
  textColor,
  verticalPadding = 6,
  horizontalPadding = 10,
  onTap,
}) => {
  return (
    <div
      onClick={onTap}
      style={{
        backgroundColor: bgColor,
        borderRadius: "4px 20px 20px 4px",
        padding: `${verticalPadding}px ${horizontalPadding}px`,
        cursor: onTap ? "pointer" : "default",
      }}
    >
      <span style={{ color: textColor || "#FFFFFF" }}>{text}</span>
    </div>
  );
};

// interface SideNodeParticleProps {
//     bgColor: string;
//     text: string;
//     textColor?: string;
//     countOfNode?: number;
//     isNodeRightSide?: boolean;
//     isNodeAlignSpaceBetween?: boolean;
//     isSameBorderRadius?: boolean;
//     verticalPadding?: number;
//     horizontalPadding?: number;
// }

// export const SideNodeParticle: React.FC<SideNodeParticleProps> = ({
//   bgColor,
//   text,
//   textColor = "#FFFFFF", // Replace with actual color
//   countOfNode = 2,
//   isNodeRightSide = true,
//   isNodeAlignSpaceBetween = false,
//   isSameBorderRadius = false,
//   verticalPadding = 6,
//   horizontalPadding = 10,
// }) => {
//   const [widgetHeight, setWidgetHeight] = useState(0);
//   const containerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (containerRef.current) {
//       const height = containerRef.current.getBoundingClientRect().height;
//       setWidgetHeight(height);
//     }
//   }, []);

//   const getNodeWidget = () => {
//     return (
//       <div
//         style={{
//           height: widgetHeight,
//           display: "flex",
//           flexDirection: "column",
//           justifyContent:
//             isNodeAlignSpaceBetween && countOfNode === 2
//               ? "space-between"
//               : "space-evenly",
//         }}
//       >
//         {Array.from({ length: countOfNode }, (_, index) => (
//           <div
//             key={index}
//             style={{
//               width: widgetHeight / (isNodeAlignSpaceBetween ? 4 : 6),
//               height: widgetHeight / (isNodeAlignSpaceBetween ? 3 : 5),
//               backgroundColor: bgColor,
//             }}
//           />
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       {!isNodeRightSide && getNodeWidget()}
//       <div
//         ref={containerRef}
//         style={{
//           borderRadius: `
//               ${
//                 isNodeRightSide && !isSameBorderRadius ? "24px 0 0 24px" : "4px"
//               }
//               ${
//                 !isNodeRightSide && !isSameBorderRadius
//                   ? "0 24px 24px 0"
//                   : "4px"
//               }`,
//           backgroundColor: bgColor,
//           padding: `${verticalPadding}px ${horizontalPadding}px`,
//         }}
//       >
//         <span style={{ color: textColor }}>{text}</span>
//       </div>
//       {isNodeRightSide && getNodeWidget()}
//     </div>
//   );
// };

interface SideNodeParticleProps {
  bgColor: string;
  text: string;
  textColor?: string;
  countOfNode?: number;
  isNodeRightSide?: boolean;
  isNodeAlignSpaceBetween?: boolean;
  isSameBorderRadius?: boolean;
  verticalPadding?: number;
  horizontalPadding?: number;
}

export const SideNodeParticle: React.FC<SideNodeParticleProps> = ({
  bgColor,
  text,
  textColor = "#FFFFFF",
  countOfNode = 2,
  isNodeRightSide = true,
  isNodeAlignSpaceBetween = false,
  isSameBorderRadius = false,
  verticalPadding = 6,
  horizontalPadding = 10,
}) => {
  const [widgetHeight, setWidgetHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.getBoundingClientRect().height;
      setWidgetHeight(height);
    }
  }, []);

  const getNodeWidget = () => {
    return (
      <div
        style={{
          height: widgetHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent:
            isNodeAlignSpaceBetween && countOfNode === 2
              ? "space-between"
              : "space-evenly",
        }}
      >
        {Array.from({ length: countOfNode }, (_, index) => (
          <div
            key={index}
            style={{
              width: widgetHeight / (isNodeAlignSpaceBetween ? 4 : 6),
              height: widgetHeight / (isNodeAlignSpaceBetween ? 3 : 5),
              backgroundColor: bgColor,
            }}
          />
        ))}
      </div>
    );
  };

  const borderRadius = isSameBorderRadius
    ? "4px"
    : isNodeRightSide
    ? "24px 0 0 24px"
    : "0 24px 24px 0";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!isNodeRightSide && getNodeWidget()}
      <div
        ref={containerRef}
        style={{
          borderRadius,
          backgroundColor: bgColor,
          padding: `${verticalPadding}px ${horizontalPadding}px`,
        }}
      >
        <span style={{ color: textColor }}>{text}</span>
      </div>
      {isNodeRightSide && getNodeWidget()}
    </div>
  );
};

interface InsideNodeParticlesProps {
  bgColor: string;
  text: string;
  textColor?: string;
  textSize?: string;
  whitePositionIndex?: number;
  dotCount?: number;
  colorList?: string[];
  isPreposition?: boolean;
  nodeWidth?: number;
}

export const InsideNodeParticles: React.FC<InsideNodeParticlesProps> = ({
  bgColor,
  text,
  textColor = "#FFFFFF", // Replace with your light text color
  whitePositionIndex = 0,
  textSize,
  dotCount = 1,
  colorList,
  isPreposition = false,
  nodeWidth = 4,
}) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        borderRadius: "4px",
        padding: "4px 8px", // Matching padding from Dart code
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ padding: "2px 0", fontSize: textSize }}>
        <span style={{ color: textColor }}>{text}</span>
      </div>

      <div
        style={{
          display: "flex",
          height: "4px",
          justifyContent: isPreposition ? "flex-start" : "center",
          gap: "2px",
        }}
      >
        {Array.from({ length: dotCount }, (_, index) => (
          <div
            key={index}
            style={{
              width: `${nodeWidth}px`,
              height: "3px", // Matching height from Dart code
              borderRadius: "1px",
              backgroundColor:
                isPreposition && colorList && colorList[index]
                  ? colorList[index]
                  : index + 1 === whitePositionIndex
                  ? "#FFFFFF" // Replace with your white color constant
                  : "#006400", // Replace with your dark green color constant
            }}
          />
        ))}
      </div>
    </div>
  );
};

interface InsideNodeParticlesProps {
  bgColor: string;
  text: string;
  textColor?: string;
  whitePositionIndex?: number;
  dotCount?: number;
  colorList?: string[];
  isPreposition?: boolean;
  nodeWidth?: number;
}

export const InsideNodeParticlesSecond: React.FC<InsideNodeParticlesProps> = ({
  bgColor,
  text,
  textColor = "#FFFFFF", // Replace with actual color if needed
  whitePositionIndex = 0,
  dotCount = 1,
  colorList = [],
  isPreposition = false,
  nodeWidth = 4,
}) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        borderRadius: "4px",
        padding: "3px 10px",
      }}
    >
      <div style={{ padding: "2px" }}>
        <span style={{ color: textColor }}>{text}</span>
      </div>

      {/* Space between text and dots */}
      <div style={{ height: "1.4em" }} />

      {/* Dots display */}
      <div
        style={{
          display: "flex",
          gap: "2px",
          height: "4px",
          overflow: "hidden",
        }}
      >
        {Array.from({ length: dotCount }).map((_, index) => (
          <div
            key={index}
            style={{
              height: "3px",
              width: `${nodeWidth}px`,
              borderRadius: "1px",
              backgroundColor:
                isPreposition && colorList
                  ? colorList[index]
                  : index + 1 === whitePositionIndex
                  ? "#FFFFFF" // Replace with your white color
                  : "#006400", // Replace with your dark green color
            }}
          />
        ))}
      </div>
    </div>
  );
};

interface RightSideTriangleParticlesProps {
  bgColor: string;
  text: string;
  textColor?: string;
  textStyle?: React.CSSProperties;
  borderRadius?: number;
  verticalPadding?: number;
  horizontalPadding?: number;
  onTap?: () => void;
}

export const RightSideTriangleParticles: React.FC<
  RightSideTriangleParticlesProps
> = ({
  bgColor,
  text,
  textColor = "#FFFFFF", // Replace with actual color if needed
  textStyle,
  borderRadius = 4,
  verticalPadding = 12,
  horizontalPadding = 10,
  onTap,
}) => {
  return (
    <>
      <TriangleShapePainter
        text={text}
        bgColor={bgColor}
        verticalPadding={verticalPadding}
        horizontalPadding={horizontalPadding}
      />
    </>
  );
};

interface TriangleShapeProps {
  bgColor?: string;
  borderRadius?: number;
  text: string;
  verticalPadding?: number;
  horizontalPadding?: number;
}

export const TriangleShapePainter: React.FC<TriangleShapeProps> = ({
  bgColor = "#4AAF57",
  borderRadius = 4,
  text,
  verticalPadding = 10,
  horizontalPadding = 20,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Device pixel ratio for sharper rendering
        const scale = window.devicePixelRatio || 1;

        // Measure text to determine canvas size
        ctx.font = "16px Arial"; // Set font for measurement
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight =
          textMetrics.actualBoundingBoxAscent +
          textMetrics.actualBoundingBoxDescent;

        // Set canvas size with scaling for high-DPI displays
        const canvasWidth = (textWidth + horizontalPadding * 2 + 20) * scale; // 20 for triangle peak
        const canvasHeight = (textHeight + verticalPadding * 2) * scale;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Set CSS width/height for display size (non-scaled)
        canvas.style.width = `${canvasWidth / scale}px`;
        canvas.style.height = `${canvasHeight / scale}px`;

        // Scale context to match the canvas scaling
        ctx.scale(scale, scale);

        // Clear the canvas and set fill color
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = bgColor;

        // Create the path for the rounded triangle shape
        const path = new Path2D();
        const radius = borderRadius;

        // Rounded triangle shape
        path.moveTo(0, radius);
        path.quadraticCurveTo(0, 0, radius, 0); // Top-left rounded corner
        path.lineTo(canvasWidth / scale - 20, 0);
        path.lineTo(canvasWidth / scale, canvasHeight / (2 * scale));
        path.lineTo(canvasWidth / scale - 20, canvasHeight / scale);
        path.lineTo(radius, canvasHeight / scale);
        path.quadraticCurveTo(
          0,
          canvasHeight / scale,
          0,
          canvasHeight / scale - radius
        );
        path.closePath();

        // Fill the shape
        ctx.fill(path);

        // Draw centered text
        ctx.fillStyle = "#FFFFFF"; // Adjust text color as needed
        ctx.textAlign = "center";
        ctx.font = "16px Arial"; // Set font style (adjust as needed)
        ctx.textBaseline = "middle";

        const centerX = (canvasWidth / scale - 20) / 2;
        const centerY = canvasHeight / scale / 2;

        ctx.fillText(text, centerX, centerY);
      }
    }
  }, [bgColor, borderRadius, text, verticalPadding, horizontalPadding]);

  return <canvas ref={canvasRef} />;
};

// interface TriangleShapeProps {
//   bgColor?: string;
//   borderRadius?: number;
//   width?: number;
//   height?: number;
//   text: string;
// }

// export const TriangleShapePainter: React.FC<TriangleShapeProps> = ({
//   bgColor = "#4AAF57", // Default background color if not provided
//   borderRadius = 4,
//   width = 100, // Default width, you can adjust this value
//   height = 50, // Default height, adjust to your needs
//   text,
// }) => {
//   const canvasRef = useRef<HTMLCanvasElement | null>(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     if (canvas) {
//       const ctx = canvas.getContext("2d");
//       if (ctx) {
//         // Clear the canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         // Set the fill style (bgColor)
//         ctx.fillStyle = bgColor;

//         // Create the path using Path2D for rounded corners and the triangle
//         const path = new Path2D();
//         const radius = borderRadius;

//         // Start the path at the top-left corner with a rounded corner
//         path.moveTo(0, radius);
//         path.quadraticCurveTo(0, 0, radius, 0); // Top-left rounded corner

//         // Draw the top side of the shape
//         path.lineTo(width - 20, 0);

//         // Draw the triangle peak
//         path.lineTo(width, height / 2);

//         // Draw the bottom side of the shape
//         path.lineTo(width - 20, height);

//         // Draw the bottom-left corner with a rounded corner
//         path.lineTo(radius, height);
//         path.quadraticCurveTo(0, height, 0, height - radius);

//         // Close the path
//         path.closePath();

//         // Fill the path
//         ctx.fill(path);
//       }
//     }
//   }, [bgColor, borderRadius, width, height]);

//   return (
//     <canvas ref={canvasRef} width={width} height={height}>
//       {text}
//     </canvas>
//   );
// };

interface TextParticlesProps {
  textColor?: string;
  text: string;
  textStyle?: React.CSSProperties;
  textSize?: string;
  onTap?: () => void;
}

export const TextParticles: React.FC<TextParticlesProps> = ({
  textColor,
  text,
  textStyle,
  textSize,
  onTap,
}) => {
  const emptyOrWhitespace = (str: string) => !str.trim();

  if (emptyOrWhitespace(text)) {
    return (
      <div
        style={{
          borderRadius: "4px",
          border: "1px solid rgba(128, 128, 128, 0.24)", // Replacing mediumGreyColor with a default grey color
        }}
      >
        <div style={{ opacity: 0.26 }}>
          {/* You can replace this with an SVG import */}
          {/* Example: <img src={yourSvg} alt="Text Placeholder" /> */}
          {/* Placeholder for SVG image */}
          <svg
            width="100"
            height="50"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 50"
          >
            <rect width="100" height="50" fill="grey" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
              SVG Text
            </text>
          </svg>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          borderRadius: "4px",
          cursor: onTap ? "pointer" : "default",
        }}
        onClick={onTap}
      >
        <span
          style={{
            ...textStyle,
            color: textColor ?? "#333", // Replacing darkTextColor with a default color
            fontSize: textSize,
            fontWeight: 400,
          }}
        >
          {text}
        </span>
      </div>
    );
  }
};
